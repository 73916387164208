var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.event["before_start"]["invert"]
    ? _c("div", [
        _c("div", { staticClass: "card-border mb16" }, [
          !_vm.author
            ? _c("div", { staticClass: "fz17" }, [
                _c("p", { staticClass: "mb20" }, [
                  _vm._v(
                    _vm._s(_vm.$t("participant.prt_tx38")) +
                      ": " +
                      _vm._s(
                        _vm.participants_drivers + _vm.participants_passengers
                      ) +
                      "\n                (" +
                      _vm._s(_vm.$t("participant.prt_tx40")) +
                      "\n                " +
                      _vm._s(
                        (_vm.event["teams"]
                          ? _vm.event["max_team"]
                          : parseInt(_vm.event["max_participant"])) +
                          parseInt(_vm.total_passenger_places)
                      ) +
                      "\n                " +
                      _vm._s(_vm.$t("participant.prt_tx40_1")) +
                      ")"
                  )
                ]),
                _vm._v(" "),
                _vm.event["passenger"]
                  ? _c("p", { staticClass: "mb20" }, [
                      _vm._v(
                        "\n                " +
                          _vm._s(_vm.$t("participant.prt_tx39")) +
                          ": " +
                          _vm._s(_vm.participants_passengers) +
                          " (" +
                          _vm._s(_vm.$t("participant.prt_tx40")) +
                          "\n                " +
                          _vm._s(_vm.total_passenger_places) +
                          " " +
                          _vm._s(_vm.$t("participant.prt_tx40_1")) +
                          ")\n            "
                      )
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _vm.event["passenger"]
                  ? _c("p", { staticClass: "mb20" }, [
                      _vm._v(
                        "\n                " +
                          _vm._s(_vm.$t("participant.prt_tx41")) +
                          ": " +
                          _vm._s(_vm.participants_drivers) +
                          " (" +
                          _vm._s(_vm.$t("participant.prt_tx40")) +
                          "\n                " +
                          _vm._s(
                            _vm.event["teams"]
                              ? _vm.event["max_team"]
                              : _vm.event["max_participant"]
                          ) +
                          " " +
                          _vm._s(_vm.$t("participant.prt_tx40_1")) +
                          ")\n            "
                      )
                    ])
                  : _vm._e()
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.participant["confirmed"]
            ? _c("div", { staticClass: "mb20" }, [
                _vm.participant["confirmed"] == 9
                  ? _c("p", { staticClass: "fz17 lh15 mb10 fw600 color-red" }, [
                      _vm._v(
                        "\n                " +
                          _vm._s(_vm.$t("participant.prt_tx20"))
                      )
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _vm.participant["confirmed"] == 10
                  ? _c("p", { staticClass: "fz17 lh15 mb10 fw600 color-red" }, [
                      _vm._v(
                        "\n                " +
                          _vm._s(_vm.$t("participant.prt_tx21"))
                      )
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _vm.participant["confirmed"] == 1
                  ? _c(
                      "p",
                      { staticClass: "fz17 lh15 mb10 fw600 color-blue" },
                      [
                        _vm._v(
                          "\n                " +
                            _vm._s(_vm.$t("participant.prt_tx13_1")) +
                            " (" +
                            _vm._s(
                              _vm.getNameById(
                                _vm.participant["role"],
                                _vm.$t("participant.prt_tx3")
                              )
                            ) +
                            ")\n            "
                        )
                      ]
                    )
                  : _vm._e(),
                _vm._v(" "),
                _vm.participant["confirmed"] == 2
                  ? _c(
                      "p",
                      { staticClass: "fz17 lh15 mb10 fw600 color-blue" },
                      [
                        _vm._v(
                          "\n                " +
                            _vm._s(_vm.$t("participant.prt_tx14"))
                        )
                      ]
                    )
                  : _vm._e(),
                _vm._v(" "),
                _vm.participant["confirmed"] == 3
                  ? _c(
                      "p",
                      { staticClass: "fz17 lh15 mb10 fw600 color-blue" },
                      [
                        _vm._v(
                          "\n                " +
                            _vm._s(_vm.$t("participant.prt_tx13")) +
                            "\n                (" +
                            _vm._s(
                              _vm.getNameById(
                                _vm.participant["role"],
                                _vm.$t("participant.prt_tx3")
                              )
                            ) +
                            ")"
                        )
                      ]
                    )
                  : _vm._e(),
                _vm._v(" "),
                _vm.event["before_start"]["invert"]
                  ? _c("div", [
                      _vm.participant["confirmed"] == 2 ||
                      _vm.participant["confirmed"] == 3
                        ? _c("div", { staticClass: "mb10" }, [
                            _c("div", { staticClass: "mb10" }, [
                              _c(
                                "a",
                                {
                                  staticClass: "btn btn_red",
                                  attrs: { href: "#" },
                                  on: {
                                    click: function($event) {
                                      $event.preventDefault()
                                      return _vm.cancel.apply(null, arguments)
                                    }
                                  }
                                },
                                [_vm._v(_vm._s(_vm.$t("participant.prt_tx19")))]
                              )
                            ]),
                            _vm._v(" "),
                            _c("div", { staticClass: "form-wrap" }, [
                              _c("p", { staticClass: "fz14 lh15 mb10" }, [
                                _vm._v(_vm._s(_vm.$t("participant.prt_tx18")))
                              ])
                            ])
                          ])
                        : _vm._e()
                    ])
                  : _vm._e()
              ])
            : _c(
                "div",
                { ref: "formParticipate", staticClass: "mb20" },
                [
                  !_vm.author
                    ? _c("div", { staticClass: "mb10" }, [
                        _c(
                          "a",
                          {
                            staticClass: "btn",
                            attrs: { href: "#" },
                            on: {
                              click: function($event) {
                                $event.preventDefault()
                                _vm.form_visible = !_vm.form_visible
                              }
                            }
                          },
                          [_vm._v(_vm._s(_vm.$t("participant.prt_tx1_1")))]
                        )
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _c(
                    "vue-slide-up-down",
                    {
                      attrs: {
                        active: _vm.form_visible || _vm.author,
                        name: "slide"
                      }
                    },
                    [
                      _c("div", [
                        _vm.author
                          ? _c(
                              "p",
                              {
                                staticClass:
                                  "fz17 lh15 fw700 warning-notification"
                              },
                              [_vm._v(_vm._s(_vm.$t("participant.prt_tx5_1")))]
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.author
                          ? _c(
                              "p",
                              { staticClass: "fz17 lh15 fw700 color-error" },
                              [_vm._v(_vm._s(_vm.$t("participant.prt_tx5_1")))]
                            )
                          : _c("p", { staticClass: "fz17 lh15" }, [
                              _vm._v(_vm._s(_vm.$t("participant.prt_tx5")))
                            ]),
                        _vm._v(" "),
                        _c(
                          "p",
                          { staticClass: "fz17 mb10 fw600 lh15 color-red" },
                          [
                            _vm._v(
                              _vm._s(_vm.event["start_formatted"]) +
                                "\n                        (" +
                                _vm._s(_vm.$t("event.prt_tx6")) +
                                "\n                        " +
                                _vm._s(_vm.event["before_start"]["days"]) +
                                "\n                        " +
                                _vm._s(_vm.$t("event.prt_tx6_1")) +
                                "\n                        " +
                                _vm._s(_vm.event["before_start"]["h"]) +
                                " " +
                                _vm._s(_vm.$t("event.prt_tx6_2")) +
                                ")\n                    "
                            )
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "mb20" },
                          [
                            _c("block-select", {
                              attrs: {
                                label: _vm.$t("participant.prt_tx1"),
                                values: _vm.$t("participant.prt_tx3"),
                                error: _vm.errors["role"]
                              },
                              model: {
                                value: _vm.participant["role"],
                                callback: function($$v) {
                                  _vm.$set(_vm.participant, "role", $$v)
                                },
                                expression: "participant['role']"
                              }
                            })
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _vm.event["passenger"] && _vm.participant["role"] == 1
                          ? _c(
                              "div",
                              { staticClass: "mb20" },
                              [
                                _c("p", { staticClass: "fz14 lh15 mb10" }, [
                                  _vm._v(
                                    _vm._s(_vm.$t("participant.prt_tx10")) +
                                      "\n                            "
                                  ),
                                  _vm.event["max_team"]
                                    ? _c("span", [
                                        _c("br"),
                                        _vm._v(
                                          "(" +
                                            _vm._s(
                                              _vm.$t("participant.prt_tx45")
                                            ) +
                                            " " +
                                            _vm._s(_vm.event["max_team"]) +
                                            "\n                                " +
                                            _vm._s(
                                              _vm.$t("participant.prt_tx46")
                                            ) +
                                            ")\n                            "
                                        )
                                      ])
                                    : _vm._e()
                                ]),
                                _vm._v(" "),
                                _c("block-input", {
                                  attrs: {
                                    label: _vm.$t("participant.prt_tx9"),
                                    error: _vm.errors["passengers"],
                                    input_class: "input-mask-3"
                                  },
                                  model: {
                                    value: _vm.participant["passengers"],
                                    callback: function($$v) {
                                      _vm.$set(
                                        _vm.participant,
                                        "passengers",
                                        $$v
                                      )
                                    },
                                    expression: "participant['passengers']"
                                  }
                                })
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "mb20" },
                          [
                            _c("block-input", {
                              attrs: {
                                label: _vm.$t("participant.prt_tx4"),
                                textarea: true,
                                error: _vm.errors["description"],
                                input_class: "input-mask-3"
                              },
                              model: {
                                value: _vm.participant["description"],
                                callback: function($$v) {
                                  _vm.$set(_vm.participant, "description", $$v)
                                },
                                expression: "participant['description']"
                              }
                            })
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _vm.event["payment"]
                          ? _c("p", { staticClass: "fz14 lh15 mb20" }, [
                              _vm._v(_vm._s(_vm.$t("participant.prt_tx8")))
                            ])
                          : _vm._e(),
                        _vm._v(" "),
                        _c(
                          "button",
                          {
                            staticClass: "btn",
                            on: {
                              click: function($event) {
                                $event.preventDefault()
                                return _vm.submit.apply(null, arguments)
                              }
                            }
                          },
                          [_vm._v(_vm._s(_vm.$t("participant.prt_tx2")))]
                        )
                      ])
                    ]
                  )
                ],
                1
              ),
          _vm._v(" "),
          Object.keys(this.participants).length
            ? _c("div", { staticClass: "mb20" }, [
                _c("p", { staticClass: "fz17 lh15 mb10 fw600 color-blue" }, [
                  _vm._v(_vm._s(_vm.$t("participant.prt_tx25")))
                ]),
                _vm._v(" "),
                _c(
                  "table",
                  { staticClass: "fz17 table" },
                  [
                    _c("tr", [
                      _c("th", { staticClass: "hide-550" }, [
                        _vm._v(_vm._s(_vm.$t("participant.prt_tx22")))
                      ]),
                      _vm._v(" "),
                      _c("th", [
                        _vm._v(_vm._s(_vm.$t("participant.prt_tx23")))
                      ]),
                      _vm._v(" "),
                      _c("th", { staticClass: "hide-550" }, [
                        _vm._v(_vm._s(_vm.$t("participant.prt_tx24")))
                      ]),
                      _vm._v(" "),
                      _vm.event["passenger"]
                        ? _c("th", { staticClass: "hide-550" }, [
                            _vm._v(_vm._s(_vm.$t("participant.prt_tx37")))
                          ])
                        : _vm._e(),
                      _vm._v(" "),
                      _c("th", [
                        _vm._v(_vm._s(_vm.$t("participant.prt_tx35")))
                      ]),
                      _vm._v(" "),
                      _c("th", [_vm._v(_vm._s(_vm.$t("participant.prt_tx26")))])
                    ]),
                    _vm._v(" "),
                    _vm._l(_vm.participants, function(participant) {
                      return _c("tr", [
                        _c("td", { staticClass: "hide-550" }, [
                          _vm._v(_vm._s(participant["created_formatted"]))
                        ]),
                        _vm._v(" "),
                        _c("td", [
                          _c(
                            "a",
                            {
                              staticClass: "link link-blue",
                              attrs: {
                                href: participant["user"]["href"],
                                target: "_blank"
                              }
                            },
                            [
                              _vm._v(
                                "\n                            " +
                                  _vm._s(participant["user"]["showname"]) +
                                  "\n                            "
                              ),
                              participant["user"]["is_online"]
                                ? _c("span", { staticClass: "ico-online" })
                                : _vm._e()
                            ]
                          )
                        ]),
                        _vm._v(" "),
                        _c("td", { staticClass: "hide-550" }, [
                          _vm._v(_vm._s(participant["description"]))
                        ]),
                        _vm._v(" "),
                        _vm.event["passenger"]
                          ? _c("td", { staticClass: "hide-550" }, [
                              _vm._v(_vm._s(participant["passengers"]))
                            ])
                          : _vm._e(),
                        _vm._v(" "),
                        _c("td", [
                          _vm._v(
                            _vm._s(
                              _vm.getNameById(
                                participant["role"],
                                _vm.$t("participant.prt_tx3")
                              )
                            )
                          )
                        ]),
                        _vm._v(" "),
                        _c("td", [
                          _c(
                            "div",
                            { staticClass: "flex flex-v-center flex_5" },
                            [
                              _c("div", { staticClass: "col0 col_5" }, [
                                _vm._v(
                                  "\n                                " +
                                    _vm._s(
                                      _vm.getNameById(
                                        participant["confirmed"],
                                        _vm.$t("participant.prt_sts19")
                                      )
                                    ) +
                                    "\n                            "
                                )
                              ]),
                              _vm._v(" "),
                              participant["confirmed"] == 2
                                ? _c("div", { staticClass: "col0 col_5" }, [
                                    _c(
                                      "button",
                                      {
                                        staticClass:
                                          "btn btn_small tooltip-bottom",
                                        attrs: {
                                          "data-title": _vm.$t(
                                            "participant.prt_tx29"
                                          )
                                        },
                                        on: {
                                          click: function($event) {
                                            $event.preventDefault()
                                            return _vm.participantConfirm(
                                              participant["id"]
                                            )
                                          }
                                        }
                                      },
                                      [
                                        _c(
                                          "svg",
                                          {
                                            attrs: {
                                              height: ".7rem",
                                              viewBox: "0 -46 417.81333 417",
                                              width: ".7rem",
                                              xmlns:
                                                "http://www.w3.org/2000/svg"
                                            }
                                          },
                                          [
                                            _c("path", {
                                              attrs: {
                                                d:
                                                  "m159.988281 318.582031c-3.988281 4.011719-9.429687 6.25-15.082031 6.25s-11.09375-2.238281-15.082031-6.25l-120.449219-120.46875c-12.5-12.5-12.5-32.769531 0-45.246093l15.082031-15.085938c12.503907-12.5 32.75-12.5 45.25 0l75.199219 75.203125 203.199219-203.203125c12.503906-12.5 32.769531-12.5 45.25 0l15.082031 15.085938c12.5 12.5 12.5 32.765624 0 45.246093zm0 0"
                                              }
                                            })
                                          ]
                                        )
                                      ]
                                    )
                                  ])
                                : _vm._e(),
                              _vm._v(" "),
                              participant["confirmed"] == 2
                                ? _c("div", { staticClass: "col0 col_5" }, [
                                    _c(
                                      "a",
                                      {
                                        staticClass:
                                          "btn btn_red btn_small tooltip-bottom",
                                        attrs: {
                                          href: "#",
                                          "data-title": _vm.$t("event.prt_tx30")
                                        },
                                        on: {
                                          click: function($event) {
                                            $event.preventDefault()
                                            return _vm.participantReject(
                                              participant["id"]
                                            )
                                          }
                                        }
                                      },
                                      [
                                        _c(
                                          "svg",
                                          {
                                            attrs: {
                                              height: ".7rem",
                                              viewBox: "0 0 365.696 365.696",
                                              width: ".7rem",
                                              xmlns:
                                                "http://www.w3.org/2000/svg"
                                            }
                                          },
                                          [
                                            _c("path", {
                                              attrs: {
                                                d:
                                                  "m243.1875 182.859375 113.132812-113.132813c12.5-12.5 12.5-32.765624 0-45.246093l-15.082031-15.082031c-12.503906-12.503907-32.769531-12.503907-45.25 0l-113.128906 113.128906-113.132813-113.152344c-12.5-12.5-32.765624-12.5-45.246093 0l-15.105469 15.082031c-12.5 12.503907-12.5 32.769531 0 45.25l113.152344 113.152344-113.128906 113.128906c-12.503907 12.503907-12.503907 32.769531 0 45.25l15.082031 15.082031c12.5 12.5 32.765625 12.5 45.246093 0l113.132813-113.132812 113.128906 113.132812c12.503907 12.5 32.769531 12.5 45.25 0l15.082031-15.082031c12.5-12.503906 12.5-32.769531 0-45.25zm0 0"
                                              }
                                            })
                                          ]
                                        )
                                      ]
                                    )
                                  ])
                                : _vm._e()
                            ]
                          )
                        ])
                      ])
                    }),
                    _vm._v(" "),
                    _c("tr", [
                      _c("td", { attrs: { colspan: "6" } }, [
                        _vm._v(
                          "\n                        " +
                            _vm._s(_vm.$t("participant.prt_tx38")) +
                            ": " +
                            _vm._s(
                              parseInt(_vm.participants_drivers) +
                                parseInt(_vm.participants_passengers)
                            ) +
                            "\n                        (" +
                            _vm._s(_vm.$t("participant.prt_tx40")) +
                            "\n                        " +
                            _vm._s(
                              parseInt(_vm.event["max_participant"]) +
                                parseInt(_vm.total_passenger_places)
                            ) +
                            " " +
                            _vm._s(_vm.$t("participant.prt_tx40_1")) +
                            ")\n                    "
                        )
                      ])
                    ]),
                    _vm._v(" "),
                    _c("tr", [
                      _vm.event["passenger"]
                        ? _c("td", { attrs: { colspan: "6" } }, [
                            _vm._v(
                              "\n                        " +
                                _vm._s(_vm.$t("participant.prt_tx39")) +
                                ": " +
                                _vm._s(_vm.participants_passengers) +
                                "\n                        (" +
                                _vm._s(_vm.$t("participant.prt_tx40")) +
                                "\n                        " +
                                _vm._s(_vm.total_passenger_places) +
                                " " +
                                _vm._s(_vm.$t("participant.prt_tx40_1")) +
                                ")\n                    "
                            )
                          ])
                        : _vm._e()
                    ]),
                    _vm._v(" "),
                    _c("tr", [
                      _vm.event["passenger"]
                        ? _c("td", { attrs: { colspan: "6" } }, [
                            _vm._v(
                              "\n                        " +
                                _vm._s(_vm.$t("participant.prt_tx41")) +
                                ": " +
                                _vm._s(_vm.participants_drivers) +
                                " (" +
                                _vm._s(_vm.$t("participant.prt_tx40")) +
                                "\n                        " +
                                _vm._s(parseInt(_vm.event["max_participant"])) +
                                "\n                        " +
                                _vm._s(_vm.$t("participant.prt_tx40_1")) +
                                ")\n                    "
                            )
                          ])
                        : _vm._e()
                    ])
                  ],
                  2
                )
              ])
            : _vm._e()
        ]),
        _vm._v(" "),
        _vm.participant["confirmed"] == 1 || _vm.participant["confirmed"] == 3
          ? _c("div", { staticClass: "flex flex-wrap-768" }, [
              _c("div", { staticClass: "col6 col12-768 mb20" }, [
                _c("div", { staticClass: "card-border" }, [
                  _c("div", { staticClass: "message" }, [
                    _c(
                      "p",
                      { staticClass: "fz17 lh15 mb10 fw600 color-blue" },
                      [_vm._v(_vm._s(_vm.$t("participant.ms_tx1")))]
                    ),
                    _vm._v(" "),
                    _c("div", { staticClass: "messages mb20" }, [
                      this.messages.length
                        ? _c(
                            "div",
                            _vm._l(this.messages, function(message) {
                              return _c("div", [
                                _c(
                                  "div",
                                  {
                                    staticClass: "messages__item mb10",
                                    class: [
                                      message["is_my"]
                                        ? "messages__item_my"
                                        : ""
                                    ]
                                  },
                                  [
                                    !message["is_my"]
                                      ? _c(
                                          "a",
                                          {
                                            staticClass:
                                              "link link-blue fz16 fw600 mb10",
                                            attrs: {
                                              href: message["user"]["href"],
                                              target: "_blank"
                                            }
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                message["user"]["showname"]
                                              )
                                            )
                                          ]
                                        )
                                      : _vm._e(),
                                    _vm._v(" "),
                                    _c("p", { staticClass: "fz16 mb5" }, [
                                      _vm._v(_vm._s(message["text"]))
                                    ]),
                                    _vm._v(" "),
                                    _c(
                                      "p",
                                      { staticClass: "color-gray fz12" },
                                      [
                                        _vm._v(
                                          _vm._s(message["created_formatted"])
                                        )
                                      ]
                                    )
                                  ]
                                )
                              ])
                            }),
                            0
                          )
                        : _c("div", [
                            _c("p", { staticClass: "fz16 color-gray" }, [
                              _vm._v(_vm._s(_vm.$t("participant.ms_tx5")))
                            ])
                          ])
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "messages__form" }, [
                      _c(
                        "div",
                        { staticClass: "mb20" },
                        [
                          _c("block-input", {
                            attrs: {
                              label: _vm.$t("participant.ms_tx2"),
                              textarea: true
                            },
                            on: { pressedEnter: _vm.sendMessage },
                            model: {
                              value: _vm.text,
                              callback: function($$v) {
                                _vm.text = $$v
                              },
                              expression: "text"
                            }
                          })
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "button",
                        { staticClass: "btn", on: { click: _vm.sendMessage } },
                        [_vm._v(_vm._s(_vm.$t("participant.ms_tx3")))]
                      )
                    ])
                  ])
                ])
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "col6 col12-768 mb20" }, [
                _c("div", { staticClass: "card-border participant-block" }, [
                  _c("div", [
                    _c(
                      "p",
                      { staticClass: "fz17 lh15 mb10 fw600 color-blue" },
                      [_vm._v(_vm._s(_vm.$t("participant.prt_tx47")))]
                    ),
                    _vm._v(" "),
                    _c("p", { staticClass: "fz17 mb10" }, [
                      _vm._v(_vm._s(_vm.$t("participant.prt_tx47_1")))
                    ]),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "mb20" },
                      _vm._l(_vm.gatheringsLocal, function(gathering) {
                        return _c("div", { staticClass: "mb5 flex1" }, [
                          _c(
                            "label",
                            {
                              staticClass: "st-checkbox flex-full mr10",
                              on: {
                                change: function($event) {
                                  return _vm.setGatheringDone(gathering)
                                }
                              }
                            },
                            [
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: gathering["done"],
                                    expression: "gathering['done']"
                                  }
                                ],
                                attrs: {
                                  type: "checkbox",
                                  disabled:
                                    gathering["user_id"] &&
                                    gathering["user_id"] !=
                                      _vm.participant["user_id"]
                                },
                                domProps: {
                                  checked: Array.isArray(gathering["done"])
                                    ? _vm._i(gathering["done"], null) > -1
                                    : gathering["done"]
                                },
                                on: {
                                  change: function($event) {
                                    var $$a = gathering["done"],
                                      $$el = $event.target,
                                      $$c = $$el.checked ? true : false
                                    if (Array.isArray($$a)) {
                                      var $$v = null,
                                        $$i = _vm._i($$a, $$v)
                                      if ($$el.checked) {
                                        $$i < 0 &&
                                          _vm.$set(
                                            gathering,
                                            "done",
                                            $$a.concat([$$v])
                                          )
                                      } else {
                                        $$i > -1 &&
                                          _vm.$set(
                                            gathering,
                                            "done",
                                            $$a
                                              .slice(0, $$i)
                                              .concat($$a.slice($$i + 1))
                                          )
                                      }
                                    } else {
                                      _vm.$set(gathering, "done", $$c)
                                    }
                                  }
                                }
                              }),
                              _vm._v(" "),
                              _c("span", { staticClass: "st-checkbox__dot" }),
                              _vm._v(" "),
                              _c("span", { staticClass: "fz17" }, [
                                _vm._v(
                                  "\n                                    " +
                                    _vm._s(gathering["text"]) +
                                    "\n                                    "
                                ),
                                gathering["author"]
                                  ? _c("span", { staticClass: "color-gray" }, [
                                      _vm._v(
                                        "\n                                        (" +
                                          _vm._s(
                                            gathering["author"]["showname"]
                                          ) +
                                          ")\n                                    "
                                      )
                                    ])
                                  : _vm._e()
                              ])
                            ]
                          ),
                          _vm._v(" "),
                          _vm.author
                            ? _c(
                                "button",
                                {
                                  staticClass:
                                    "btn btn_red btn_small btn_small_svg",
                                  on: {
                                    click: function($event) {
                                      return _vm.removeGathering(gathering)
                                    }
                                  }
                                },
                                [
                                  _vm._v(
                                    "\n                                ×\n                            "
                                  )
                                ]
                              )
                            : _vm._e()
                        ])
                      }),
                      0
                    ),
                    _vm._v(" "),
                    _vm.author
                      ? _c("div", { staticClass: "flex1 flex-v-start" }, [
                          _c(
                            "div",
                            { staticClass: "mr10 flex-full" },
                            [
                              _c("block-input", {
                                attrs: {
                                  label: _vm.$t("participant.prt_tx48"),
                                  error: _vm.errorGathering["text"]
                                },
                                model: {
                                  value: _vm.tempGathering,
                                  callback: function($$v) {
                                    _vm.tempGathering = $$v
                                  },
                                  expression: "tempGathering"
                                }
                              })
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "button",
                            {
                              staticClass: "btn btn_full_svg",
                              on: { click: _vm.addGathering }
                            },
                            [
                              _c(
                                "svg",
                                { attrs: { width: "1rem", height: "1rem" } },
                                [
                                  _c("use", {
                                    attrs: { "xlink:href": "#ico-plus" }
                                  })
                                ]
                              )
                            ]
                          )
                        ])
                      : _vm._e()
                  ])
                ])
              ])
            ])
          : _vm._e()
      ])
    : _c("div", [
        _c("div", { staticClass: "flex" }, [
          this.messages.length
            ? _c("div", { staticClass: "col6 col12-768 mb20" }, [
                _c("div", { staticClass: "message card-border" }, [
                  _c("p", { staticClass: "fz17 lh15 mb10 fw600 color-blue" }, [
                    _vm._v(_vm._s(_vm.$t("participant.ms_tx1")))
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "messages" },
                    _vm._l(this.messages, function(message) {
                      return _c("div", [
                        _c(
                          "div",
                          {
                            staticClass: "messages__item mb10",
                            class: [message["is_my"] ? "messages__item_my" : ""]
                          },
                          [
                            !message["is_my"]
                              ? _c(
                                  "a",
                                  {
                                    staticClass:
                                      "link link-blue fz16 fw600 mb10",
                                    attrs: {
                                      href: message["user"]["href"],
                                      target: "_blank"
                                    }
                                  },
                                  [_vm._v(_vm._s(message["user"]["showname"]))]
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            _c("p", { staticClass: "fz16 mb5" }, [
                              _vm._v(_vm._s(message["text"]))
                            ]),
                            _vm._v(" "),
                            _c("p", { staticClass: "color-gray fz12" }, [
                              _vm._v(_vm._s(message["created_formatted"]))
                            ])
                          ]
                        )
                      ])
                    }),
                    0
                  )
                ])
              ])
            : _vm._e(),
          _vm._v(" "),
          this.gatheringsLocal.length
            ? _c("div", { staticClass: "col6 col12-768 mb20" }, [
                _c(
                  "div",
                  { staticClass: "card-border" },
                  [
                    _c(
                      "p",
                      { staticClass: "fz17 lh15 mb10 fw600 color-blue" },
                      [_vm._v(_vm._s(_vm.$t("participant.prt_tx47")))]
                    ),
                    _vm._v(" "),
                    _vm._l(_vm.gatheringsLocal, function(gathering) {
                      return _c("div", { staticClass: "mb5 flex1" }, [
                        _c("p", { staticClass: "flex-full mr10" }, [
                          _c("span", { staticClass: "fz17" }, [
                            _vm._v(
                              "\n                            " +
                                _vm._s(gathering["text"]) +
                                "\n                            "
                            ),
                            gathering["author"]
                              ? _c("span", { staticClass: "color-gray" }, [
                                  _vm._v(
                                    "\n                                (" +
                                      _vm._s(gathering["author"]["showname"]) +
                                      ")\n                            "
                                  )
                                ])
                              : _vm._e()
                          ])
                        ])
                      ])
                    })
                  ],
                  2
                )
              ])
            : _vm._e()
        ])
      ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }