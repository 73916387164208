var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "bb1 mb20 pb20" }, [
    _c("div", { staticClass: "mb5 flex1 flex-v-start" }, [
      _vm.travel["status"] == 1
        ? _c(
            "a",
            {
              staticClass: "link link-orange mr10",
              attrs: { href: _vm.travel["href"] }
            },
            [
              _vm._v(
                "\n            " + _vm._s(_vm.travel["name"]) + "\n        "
              )
            ]
          )
        : _c("span", { staticClass: "color-error" }, [
            _vm._v(
              "\n            " +
                _vm._s(
                  _vm.getNameById(_vm.travel["status"], _vm.$t("travel.states"))
                )
            ),
            _c("br"),
            _vm._v("\n            " + _vm._s(_vm.travel["name"]) + "\n        ")
          ]),
      _vm._v(" "),
      _c("span", { staticClass: "card-bages" }, [
        _vm.travel["draft"]
          ? _c(
              "span",
              {
                staticClass:
                  "card-bages__item card-bages__item_draft tooltip-left",
                attrs: { "data-title": _vm.$t("event.txt3") }
              },
              [
                _c("svg", [
                  _c("use", { attrs: { "xlink:href": "#ico-draft" } })
                ])
              ]
            )
          : _vm._e()
      ])
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "flex1 flex-wrap lh12" }, [
      _c("p", { staticClass: "fz16 mr5" }, [
        _vm._v(_vm._s(_vm.travel["country_name"]) + ",")
      ]),
      _vm._v(" "),
      _c("p", { staticClass: "fz16 mr5" }, [
        _vm._v(_vm._s(_vm.travel["region"]) + ",")
      ]),
      _vm._v(" "),
      _c("p", { staticClass: "fz16" }, [
        _vm._v(_vm._s(_vm.travel["distance"]) + " " + _vm._s(_vm.$t("text.km")))
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }