var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "form-item form-item_slider" }, [
    _c("div", { staticClass: "fz17 fw600 mb20" }, [_vm._v(_vm._s(this.title))]),
    _vm._v(" "),
    _c("div", { staticClass: "flex flex_5" }, [
      _c("div", { staticClass: "col6 col_5" }, [
        _c("div", { staticClass: "st-input1", class: _vm.classObjectMin }, [
          this.input_min != this.min
            ? _c(
                "div",
                {
                  staticClass: "form-item__clear",
                  on: { click: _vm.setMinDefault }
                },
                [
                  _c("svg", [
                    _c("use", { attrs: { "xlink:href": "#ico-close" } })
                  ])
                ]
              )
            : _vm._e(),
          _vm._v(" "),
          _c("label", { staticClass: "st-input1__label" }, [
            _vm._v(_vm._s(_vm.$t("text1")))
          ]),
          _vm._v(" "),
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.input_min,
                expression: "input_min"
              }
            ],
            staticClass:
              "st-input1__input form-item__silder-min js-input-number",
            attrs: { type: "text" },
            domProps: { value: _vm.input_min },
            on: {
              input: function($event) {
                if ($event.target.composing) {
                  return
                }
                _vm.input_min = $event.target.value
              }
            }
          })
        ])
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "col6 col_5" }, [
        _c("div", { staticClass: "st-input1", class: _vm.classObjectMax }, [
          this.input_max != this.max
            ? _c(
                "div",
                {
                  staticClass: "form-item__clear",
                  on: { click: _vm.setMaxDefault }
                },
                [
                  _c("svg", [
                    _c("use", { attrs: { "xlink:href": "#ico-close" } })
                  ])
                ]
              )
            : _vm._e(),
          _vm._v(" "),
          _c("label", { staticClass: "st-input1__label" }, [
            _vm._v(_vm._s(_vm.$t("text2")))
          ]),
          _vm._v(" "),
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.input_max,
                expression: "input_max"
              }
            ],
            staticClass:
              "st-input1__input form-item__silder-max js-input-number",
            attrs: { type: "text" },
            domProps: { value: _vm.input_max },
            on: {
              input: function($event) {
                if ($event.target.composing) {
                  return
                }
                _vm.input_max = $event.target.value
              }
            }
          })
        ])
      ])
    ]),
    _vm._v(" "),
    _c("div", { ref: "form-item__silder" })
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }