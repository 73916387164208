var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    _vm.tagCard,
    {
      tag: "component",
      staticClass: "card-border  block color-dark flex1 flex-column",
      class: [!_vm.small_link ? "link link-bordered" : ""],
      attrs: { href: _vm.travel["href"] }
    },
    [
      _vm.small_link
        ? _c("p", { staticClass: "fz17 lh15 mb10 fw600 color-blue" }, [
            _vm._v(_vm._s(_vm.$t("txt1")))
          ])
        : _vm._e(),
      _vm._v(" "),
      _c("div", { staticClass: "flex mb5 flex-full" }, [
        _c(
          "div",
          { staticClass: "col8" },
          [
            _c(
              _vm.tagInner,
              {
                tag: "component",
                staticClass: "link link-orange fz18 lh11 mb5",
                attrs: { href: _vm.travel["href"] }
              },
              [
                _vm._v(
                  "\n                " +
                    _vm._s(_vm.travel["name"]) +
                    "\n            "
                )
              ]
            ),
            _vm._v(" "),
            _c("div", { staticClass: "flex1 flex-wrap fz16 lh12 fw600 mb5" }, [
              _c("p", { staticClass: "mr5" }, [
                _vm._v(_vm._s(_vm.travel["country_name"]) + ",")
              ]),
              _vm._v(" "),
              _c("p", { staticClass: "mr5" }, [
                _vm._v(_vm._s(_vm.travel["region"]) + ",")
              ]),
              _vm._v(" "),
              _c("p", {}, [
                _vm._v(
                  _vm._s(_vm.travel["distance"]) +
                    " " +
                    _vm._s(_vm.$t("text.km"))
                )
              ])
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "flex1 flex-wrap fz14 lh12 mb5" }, [
              _c("p", { staticClass: "mr5" }, [
                _vm._v(
                  _vm._s(
                    _vm.getNameById(
                      _vm.travel["surface"],
                      _vm.$t("travel.surfaces")
                    )
                  ) + ","
                )
              ]),
              _vm._v(" "),
              _c("p", { staticClass: "mr5" }, [
                _vm._v(
                  _vm._s(
                    _vm.getNameById(
                      _vm.travel["difficult"],
                      _vm.$t("text.difficult")
                    )
                  ) + ","
                )
              ]),
              _vm._v(" "),
              _c("p", {}, [
                _vm._v(
                  _vm._s(_vm.$t("txt6")) +
                    ": " +
                    _vm._s(
                      _vm.getNameById(
                        _vm.travel["escort"],
                        _vm.$t("text.escort")
                      )
                    )
                )
              ])
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "mb5 hide-550" }, [
              _c(
                "div",
                { staticClass: "categ__months" },
                _vm._l(12, function(i) {
                  return _c(
                    "div",
                    { staticClass: "categ__month-wrap flex1" },
                    [
                      _c("p", { staticClass: "categ__month-name fz14" }, [
                        _vm._v(
                          _vm._s(
                            _vm.$t("travel.availabilities_short")[i - 1]["name"]
                          )
                        )
                      ]),
                      _vm._v(" "),
                      _vm._l(2, function(j) {
                        return _c("div", {
                          staticClass: "categ__month categ__month",
                          class: [
                            _vm.travel["availability_parsed"].indexOf(
                              i + "_" + j
                            ) > -1
                              ? "categ__month categ__month_active"
                              : ""
                          ]
                        })
                      })
                    ],
                    2
                  )
                }),
                0
              )
            ]),
            _vm._v(" "),
            _c("p", { staticClass: "fz14 lh12 hide-550" }, [
              _vm._v(_vm._s(_vm.travel["short_description1"]))
            ])
          ],
          1
        ),
        _vm._v(" "),
        _c("div", { staticClass: "col4" }, [
          _vm.travel["image"]
            ? _c("div", { staticClass: "categ__image" }, [
                _c("img", {
                  staticClass: "block",
                  attrs: { src: _vm.travel["image"], alt: _vm.travel["name"] }
                })
              ])
            : _vm._e()
        ])
      ]),
      _vm._v(" "),
      _c("div", { staticClass: " mb5 show-550" }, [
        _c(
          "div",
          { staticClass: "categ__months" },
          _vm._l(12, function(i) {
            return _c(
              "div",
              { staticClass: "categ__month-wrap flex1" },
              [
                _c("p", { staticClass: "categ__month-name fz14" }, [
                  _vm._v(
                    _vm._s(_vm.$t("travel.availabilities_short")[i - 1]["name"])
                  )
                ]),
                _vm._v(" "),
                _vm._l(2, function(j) {
                  return _c("div", {
                    staticClass: "categ__month categ__month",
                    class: [
                      _vm.travel["availability_parsed"].indexOf(i + "_" + j) >
                      -1
                        ? "categ__month categ__month_active"
                        : ""
                    ]
                  })
                })
              ],
              2
            )
          }),
          0
        )
      ]),
      _vm._v(" "),
      _c("p", { staticClass: "fz14 lh12 mb5 show-550" }, [
        _vm._v(_vm._s(_vm.travel["short_description1"]))
      ]),
      _vm._v(" "),
      _vm.travel["user"]
        ? _c("div", { staticClass: "flex1 flex-v-center" }, [
            _c("p", { staticClass: "fz16 color-gray mr10 hide-550" }, [
              _vm._v(_vm._s(_vm.$t("txt8")) + ":")
            ]),
            _vm._v(" "),
            _c(
              "a",
              {
                staticClass: "fz16 link link-blue",
                attrs: { href: _vm.travel["user"]["href"] }
              },
              [_vm._v(_vm._s(_vm.travel["user"]["showname"]))]
            )
          ])
        : _vm._e(),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "categ__transport flex1 flex-v-center flex-wrap" },
        [
          _c("p", { staticClass: "fz16 color-gray mr10 mb5 hide-550" }, [
            _vm._v(_vm._s(_vm.$t("txt7")) + ":")
          ]),
          _vm._v(" "),
          _vm._l(_vm.travel["travel_transports"], function(transport) {
            return _c("div", {
              staticClass: "tooltip-bottom mr5 mb5",
              attrs: { "data-title": transport["name"] },
              domProps: { innerHTML: _vm._s(transport["svg"]) }
            })
          })
        ],
        2
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }