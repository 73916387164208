var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a",
    {
      staticClass:
        "card-border link block color-dark link-bordered flex1 flex-column",
      attrs: { href: _vm.event["href"] }
    },
    [
      _c("div", { staticClass: "flex mb5 flex-full" }, [
        _c(
          "div",
          { staticClass: "col8" },
          [
            _c("div", { staticClass: "mb5 flex1 flex-v-start" }, [
              _c("p", { staticClass: "link link-orange mr10 fz18 lh11" }, [
                _vm._v(
                  "\n                    " +
                    _vm._s(
                      _vm.event["name"]
                        ? _vm.event["name_formatted"] + ", "
                        : ""
                    )
                ),
                _vm.event["name"] ? _c("br") : _vm._e(),
                _vm._v(
                  "\n                    " +
                    _vm._s(_vm.$t("event.txt1")) +
                    ": " +
                    _vm._s(_vm.event["start_formatted"])
                ),
                _c("br")
              ]),
              _vm._v(" "),
              _c("span", { staticClass: "card-bages" }, [
                _vm.event["draft"]
                  ? _c(
                      "span",
                      {
                        staticClass:
                          "card-bages__item card-bages__item_draft tooltip-bottom",
                        attrs: { "data-title": _vm.$t("event.txt3") }
                      },
                      [
                        _c("svg", [
                          _c("use", { attrs: { "xlink:href": "#ico-draft" } })
                        ])
                      ]
                    )
                  : _vm._e(),
                _vm._v(" "),
                _vm.event["payment"]
                  ? _c(
                      "span",
                      {
                        staticClass:
                          "card-bages__item card-bages__item_pay tooltip-bottom",
                        attrs: { "data-title": _vm.$t("event.txt4") }
                      },
                      [
                        _c("svg", [
                          _c("use", { attrs: { "xlink:href": "#ico-payment" } })
                        ])
                      ]
                    )
                  : _vm._e(),
                _vm._v(" "),
                _vm.event["type"] == 2
                  ? _c(
                      "span",
                      {
                        staticClass:
                          "card-bages__item card-bages__item_type tooltip-bottom",
                        attrs: { "data-title": _vm.$t("event.type")[1]["name"] }
                      },
                      [
                        _c("svg", [
                          _c("use", {
                            attrs: { "xlink:href": "#ico-event-type2" }
                          })
                        ])
                      ]
                    )
                  : _vm._e(),
                _vm._v(" "),
                _vm.event["type"] == 3
                  ? _c(
                      "span",
                      {
                        staticClass:
                          "card-bages__item card-bages__item_type tooltip-bottom",
                        attrs: { "data-title": _vm.$t("event.type")[2]["name"] }
                      },
                      [
                        _c("svg", [
                          _c("use", {
                            attrs: { "xlink:href": "#ico-event-type3" }
                          })
                        ])
                      ]
                    )
                  : _vm._e(),
                _vm._v(" "),
                _vm.event["passenger"]
                  ? _c(
                      "span",
                      {
                        staticClass:
                          "card-bages__item card-bages__item_passenger tooltip-bottom",
                        attrs: { "data-title": _vm.$t("event.txt5") }
                      },
                      [
                        _c("svg", [
                          _c("use", {
                            attrs: { "xlink:href": "#ico-passenger" }
                          })
                        ])
                      ]
                    )
                  : _vm._e()
              ])
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "flex1 flex-wrap fz16 lh12 fw600 mb5" }, [
              _c("p", { staticClass: "mr5" }, [
                _vm._v(
                  _vm._s(_vm.$t("event.txt2")) +
                    ' "' +
                    _vm._s(_vm.event["travel"]["name"]) +
                    '"'
                )
              ]),
              _vm._v(" "),
              _c("p", { staticClass: "mr5" }, [
                _vm._v(_vm._s(_vm.event["country_name"]) + ",")
              ]),
              _vm._v(" "),
              _c("p", { staticClass: "mr5" }, [
                _vm._v(_vm._s(_vm.event["travel"]["region"]) + ",")
              ]),
              _vm._v(" "),
              _c("p", { staticClass: "mr5" }, [
                _vm._v(
                  _vm._s(_vm.event["travel"]["distance"]) +
                    " " +
                    _vm._s(_vm.$t("text.km")) +
                    ","
                )
              ]),
              _vm._v(" "),
              _c("p", {}, [
                _vm._v(
                  _vm._s(_vm.event["length_count"]) +
                    " " +
                    _vm._s(_vm.event["length_text"])
                )
              ])
            ]),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "flex1 flex-wrap fz14 lh12 mb5 hide-550" },
              [
                _c("p", { staticClass: "mr5" }, [
                  _vm._v(
                    _vm._s(
                      _vm.getNameById(
                        _vm.event["travel"]["surface"],
                        _vm.$t("travel.surfaces")
                      )
                    ) + ","
                  )
                ]),
                _vm._v(" "),
                _c("p", { staticClass: "mr5" }, [
                  _vm._v(
                    _vm._s(
                      _vm.getNameById(
                        _vm.event["difficult"],
                        _vm.$t("text.difficult")
                      )
                    ) + ","
                  )
                ]),
                _vm._v(" "),
                _c("p", {}, [
                  _vm._v(
                    _vm._s(_vm.$t("txt6")) +
                      " " +
                      _vm._s(
                        _vm.getNameById(
                          _vm.event["travel"]["escort"],
                          _vm.$t("text.escort")
                        )
                      )
                  )
                ])
              ]
            ),
            _vm._v(" "),
            _c("div", { staticClass: "mb5 hide-550" }, [
              _c(
                "div",
                { staticClass: "categ__months" },
                _vm._l(12, function(i) {
                  return _c(
                    "div",
                    { staticClass: "categ__month-wrap flex1" },
                    [
                      _c("p", { staticClass: "categ__month-name fz14" }, [
                        _vm._v(
                          _vm._s(
                            _vm.$t("travel.availabilities_short")[i - 1]["name"]
                          )
                        )
                      ]),
                      _vm._v(" "),
                      _vm._l(2, function(j) {
                        return _c("div", {
                          staticClass: "categ__month categ__month",
                          class: [
                            _vm.event["travel"]["availability"].indexOf(
                              i + "_" + j
                            ) > -1
                              ? "categ__month categ__month_active"
                              : ""
                          ]
                        })
                      })
                    ],
                    2
                  )
                }),
                0
              )
            ]),
            _vm._v(" "),
            _c("p", { staticClass: "fz14 lh12" }, [
              _vm._v(_vm._s(_vm.event["description1"]))
            ]),
            _vm._v(" "),
            _vm._l(_vm.event["event_targets"], function(target) {
              return _c("p", { staticClass: "fz14 lh12 mr10" }, [
                _vm._v(
                  "\n                " +
                    _vm._s(target["name"]) +
                    "\n            "
                )
              ])
            })
          ],
          2
        ),
        _vm._v(" "),
        _c("div", { staticClass: "col4" }, [
          _vm.event["image"]
            ? _c("div", { staticClass: "categ__image" }, [
                _c("img", {
                  staticClass: "block",
                  attrs: { src: _vm.event["image"], alt: "" }
                })
              ])
            : _vm._e()
        ])
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "mb5 show-550" }, [
        _c("div", { staticClass: "flex1 flex-wrap fz14 lh12" }, [
          _c("p", { staticClass: "mr5" }, [
            _vm._v(
              _vm._s(
                _vm.getNameById(
                  _vm.event["travel"]["surface"],
                  _vm.$t("travel.surfaces")
                )
              ) + ","
            )
          ]),
          _vm._v(" "),
          _c("p", { staticClass: "mr5" }, [
            _vm._v(
              _vm._s(
                _vm.getNameById(
                  _vm.event["difficult"],
                  _vm.$t("text.difficult")
                )
              ) + ","
            )
          ]),
          _vm._v(" "),
          _c("p", {}, [
            _vm._v(
              _vm._s(_vm.$t("txt6")) +
                " " +
                _vm._s(
                  _vm.getNameById(
                    _vm.event["travel"]["escort"],
                    _vm.$t("text.escort")
                  )
                )
            )
          ])
        ])
      ]),
      _vm._v(" "),
      _c("div", { staticClass: " mb5 show-550" }, [
        _c(
          "div",
          { staticClass: "categ__months" },
          _vm._l(12, function(i) {
            return _c(
              "div",
              { staticClass: "categ__month-wrap flex1" },
              [
                _c("p", { staticClass: "categ__month-name fz14" }, [
                  _vm._v(
                    _vm._s(_vm.$t("travel.availabilities_short")[i - 1]["name"])
                  )
                ]),
                _vm._v(" "),
                _vm._l(2, function(j) {
                  return _c("div", {
                    staticClass: "categ__month categ__month",
                    class: [
                      _vm.event["travel"]["availability"].indexOf(i + "_" + j) >
                      -1
                        ? "categ__month categ__month_active"
                        : ""
                    ]
                  })
                })
              ],
              2
            )
          }),
          0
        )
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "categ__transport flex1 flex-v-center flex-wrap" },
        [
          _c("p", { staticClass: "fz16 color-gray mr10 mb5" }, [
            _vm._v(_vm._s(_vm.$t("txt7")) + ":")
          ]),
          _vm._v(" "),
          _vm._l(_vm.event["event_transports"], function(transport) {
            return _c("div", {
              staticClass: "tooltip-bottom mr5 mb5",
              attrs: { "data-title": transport["name"] },
              domProps: { innerHTML: _vm._s(transport["svg"]) }
            })
          })
        ],
        2
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }