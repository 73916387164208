var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      { staticClass: "st-input1 st-input1_date", class: _vm.classObject },
      [
        _c("label", { staticClass: "st-input1__label" }, [
          _vm._v(_vm._s(_vm.label))
        ]),
        _vm._v(" "),
        _c("date-picker", {
          attrs: {
            type: _vm.type,
            "value-type": this.value_type,
            format: this.format,
            "disabled-date": _vm.disabledBeforeToday,
            "minute-step": 5,
            "disabled-time": _vm.disabledBeforeNow,
            lang: _vm.lang,
            editable: false
          },
          model: {
            value: _vm.valueLocal,
            callback: function($$v) {
              _vm.valueLocal = $$v
            },
            expression: "valueLocal"
          }
        }),
        _vm._v(" "),
        this.valueLocal
          ? _c(
              "div",
              {
                staticClass: "form-item__clear",
                on: { click: _vm.clearValue }
              },
              [
                _c("svg", [
                  _c("use", { attrs: { "xlink:href": "#ico-close" } })
                ])
              ]
            )
          : _vm._e()
      ],
      1
    ),
    _vm._v(" "),
    this.errorLocal
      ? _c("div", { staticClass: "st-input1__error" }, [
          _vm._v(_vm._s(this.errorLocal[0]))
        ])
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }