module.exports = {
    "surface_name": "Тип поверхности",
    "surfaces": [
        {
            "id": 10,
            "name": "Автобан, автомагистраль"
        }, {
            "id": 20,
            "name": "Асфальтированная дорога"
        }, {
            "id": 30,
            "name": "Разбитая асфальтированная дорога"
        }, {
            "id": 40,
            "name": "Ровная грунтовая дорога"
        }, {
            "id": 50,
            "name": "Разбитая грунтовая дорога"
        }, {
            "id": 60,
            "name": "Бездорожье"
        }, {
            "id": 70,
            "name": "Бездорожье с бродами"
        }, {
            "id": 80,
            "name": "Бездорожье с узкими участками"
        }, {
            "id": 90,
            "name": "Бездорожье с узкими участками и бродами"
        }, {
            "id": 100,
            "name": "Скалы"
        }, {
            "id": 110,
            "name": "Скалы с грязью"
        }, {
            "id": 120,
            "name": "Скалы с узкими участками"
        }, {
            "id": 130,
            "name": "Скалы с бродами"
        }, {
            "id": 140,
            "name": "Скалы со снегом и льдом"
        }, {
            "id": 150,
            "name": "Снег, лед"
        }, {
            "id": 160,
            "name": "Водоем (река, озеро)"
        }, {
            "id": 170,
            "name": "Водоем с порогами, водопадами"
        }, {
            "id": 180,
            "name": "Водоем искусственный"
        }, {
            "id": 190,
            "name": "Водоем (океан, море)"
        }, {
            "id": 200,
            "name": "Пещеры, подземелья"
        }, {
            "id": 210,
            "name": "Велосипедная дорожка"
        }, {
            "id": 220,
            "name": "Тротуар"
        }, {
            "id": 230,
            "name": "Стадион"
        }, {
            "id": 240,
            "name": "Трек"
        }, {
            "id": 250,
            "name": "По воздуху"
        }
    ],
    "availabilities_name": "Период доступности",
    "availabilities": [
        {
            "id": 1,
            "name": "январь"
        }, {
            "id": 2,
            "name": "февраль"
        }, {
            "id": 3,
            "name": "март"
        }, {
            "id": 4,
            "name": "апрель"
        }, {
            "id": 5,
            "name": "май"
        }, {
            "id": 6,
            "name": "июнь"
        }, {
            "id": 7,
            "name": "июль"
        }, {
            "id": 8,
            "name": "август"
        }, {
            "id": 9,
            "name": "сентябрь"
        }, {
            "id": 10,
            "name": "октябрь"
        }, {
            "id": 11,
            "name": "ноябрь"
        }, {
            "id": 12,
            "name": "декабрь"
        },
    ],
    "availabilities_short": [
        {
            "id": 1,
            "name": "янв"
        }, {
            "id": 2,
            "name": "фев"
        }, {
            "id": 3,
            "name": "март"
        }, {
            "id": 4,
            "name": "апр"
        }, {
            "id": 5,
            "name": "май"
        }, {
            "id": 6,
            "name": "июнь"
        }, {
            "id": 7,
            "name": "июль"
        }, {
            "id": 8,
            "name": "авг"
        }, {
            "id": 9,
            "name": "сент"
        }, {
            "id": 10,
            "name": "окт"
        }, {
            "id": 11,
            "name": "ноя"
        }, {
            "id": 12,
            "name": "дек"
        },
    ],
    "states": [
        {
            "id": 1,
            "name": "Активный"
        }, {
            "id": 2,
            "name": "Отключен по просьбе пользователя"
        }, {
            "id": 3,
            "name": "Отключен администратором"
        }
    ],
    "ntf_cns_tx4" : "Название",
}
