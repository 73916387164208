module.exports = {
    "clear": "Сбросить",
    "difficult_name": "Сложность",
    "difficult": [
        {
            "id": 10,
            "name": "Для новичков"
        }, {
            "id": 20,
            "name": "Нужен небольшой опыт"
        }, {
            "id": 30,
            "name": "Для продвинутых"
        }, {
            "id": 40,
            "name": "Для опытных"
        }, {
            "id": 50,
            "name": "Для экспертов"
        }, {
            "id": 60,
            "name": "Экстремально"
        }, {
            "id": 100,
            "name": "-Неизвестно-"
        },
    ],
    "km": "км",
    "escort_name": "Гид/проводник",
    "escort": [
        {
            "id": 1,
            "name": "Необходим"
        }, {
            "id": 10,
            "name": "Желателен"
        }, {
            "id": 20,
            "name": "Не обязателен"
        }
    ],
    "nothing_found": "Ничего не найдено. Попробуйте изменить условия поиска",
    "ct_tx2": "Фильтр",
    "ct_tx3": "Применить",
    "ct_tx4": "Сбросить",
    "ct_tx18": "Дистанция, км",
    "transport_name": "Транспорт",
    "tab1": "Список",
    "tab2": "Карта",
    "start": "Старт",
    "yes": "Да",
    "no": "Нет",
    "error_other": "Произошла ошибка. Попробуйте обновить страницу и повторить попытку",
    "error_bug": "Ошибка формата данных. Данные отправлены администратору, в ближайшее время мы ее устраним",
    "create_edit": "Сохранить изменения",
    "draft": "Сохранить черновик",
    "draft_move": "Перенести в черновики",
    "draft_publish": "Опубликовать",
    "delete": "Удалить",
    "reload": "Обновите страницу, и попробуйте еще раз",
    "go_to_card": "Перейти в карточку",
    "filter": "Фильтр",
    "leave_page": "Если вы покинете страницу изменения не сохранятся. Продолжить?",
    "cancel": "Отмена"
};
