var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "flex flex-wrap" }, [
    _c("div", { staticClass: "col6 col12-768" }, [
      _c("p", { staticClass: "fz17 fw600 mb10" }, [
        _vm._v(_vm._s(_vm.$t("account.ava")))
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "mb20 single-photo" },
        [
          _c("block-multi-photo", {
            attrs: { files: _vm.localUser["images"], max: 1 },
            model: {
              value: _vm.localUser["images"],
              callback: function($$v) {
                _vm.$set(_vm.localUser, "images", $$v)
              },
              expression: "localUser['images']"
            }
          })
        ],
        1
      ),
      _vm._v(" "),
      _c("p", { staticClass: "fz17 mb20" }, [
        _c("span", { staticClass: "color-gray" }, [
          _vm._v(_vm._s(_vm.$t("auth.input_email")))
        ]),
        _vm._v(": " + _vm._s(_vm.localUser["email"]) + "\n        ")
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "mb20" },
        [
          _c("block-input", {
            attrs: {
              label: _vm.$t("auth.input_phone"),
              error: _vm.errors["phone"],
              input_phone: true,
              autocompleteoff: true
            },
            model: {
              value: _vm.localUser["phone"],
              callback: function($$v) {
                _vm.$set(_vm.localUser, "phone", $$v)
              },
              expression: "localUser['phone']"
            }
          })
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "mb20" },
        [
          _c("p", { staticClass: "fz17 fw600 mb10" }, [
            _vm._v(_vm._s(_vm.$t("account.acd_tx1")))
          ]),
          _vm._v(" "),
          _c("block-input", {
            attrs: {
              label: _vm.$t("auth.input_password"),
              error: _vm.errors["password"],
              type: "password",
              autocompleteoff: true
            },
            model: {
              value: _vm.localUser["password"],
              callback: function($$v) {
                _vm.$set(_vm.localUser, "password", $$v)
              },
              expression: "localUser['password']"
            }
          })
        ],
        1
      )
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "col6 col12-768" }, [
      _vm.localUser["type"]
        ? _c("div", [
            _c("p", { staticClass: "fz17 mb20" }, [
              _c("span", { staticClass: "color-gray" }, [
                _vm._v(_vm._s(_vm.$t("account.acd_tx4")) + ":")
              ]),
              _vm._v(
                "\n                " +
                  _vm._s(
                    _vm.getNameById(
                      _vm.localUser["type"],
                      _vm.$t("account.acd_tx5")
                    )
                  ) +
                  "\n            "
              )
            ]),
            _vm._v(" "),
            _vm.localUser["type"] == 1
              ? _c(
                  "div",
                  [
                    _c("div", { staticClass: "flex flex_5" }, [
                      _c(
                        "div",
                        { staticClass: "col6 col_5 mb20" },
                        [
                          _c("block-input", {
                            attrs: {
                              label: _vm.$t("auth.input_name"),
                              error: _vm.errors["name"]
                            },
                            model: {
                              value: _vm.localUser["name"],
                              callback: function($$v) {
                                _vm.$set(_vm.localUser, "name", $$v)
                              },
                              expression: "localUser['name']"
                            }
                          })
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "col6 col_5 mb20" },
                        [
                          _c("block-input", {
                            attrs: {
                              label: _vm.$t("auth.input_lastname"),
                              error: _vm.errors["lastname"]
                            },
                            model: {
                              value: _vm.localUser["lastname"],
                              callback: function($$v) {
                                _vm.$set(_vm.localUser, "lastname", $$v)
                              },
                              expression: "localUser['lastname']"
                            }
                          })
                        ],
                        1
                      )
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "flex flex_5" }, [
                      _c(
                        "div",
                        { staticClass: "col6 col_5 mb20" },
                        [
                          _c("block-input", {
                            attrs: {
                              label: _vm.$t("account.aci_tx1"),
                              error: _vm.errors["nickname"]
                            },
                            model: {
                              value: _vm.localUser["nickname"],
                              callback: function($$v) {
                                _vm.$set(_vm.localUser, "nickname", $$v)
                              },
                              expression: "localUser['nickname']"
                            }
                          })
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "col6 col_5 mb20" },
                        [
                          _c("block-select", {
                            attrs: {
                              label: _vm.$t("account.aci_tx6"),
                              values: _vm.$t("account.aci_tx5"),
                              error: _vm.errors["gender"]
                            },
                            model: {
                              value: _vm.localUser["gender"],
                              callback: function($$v) {
                                _vm.$set(_vm.localUser, "gender", $$v)
                              },
                              expression: "localUser['gender']"
                            }
                          })
                        ],
                        1
                      )
                    ]),
                    _vm._v(" "),
                    _c("p", { staticClass: "fz17 mb10" }, [
                      _vm._v(_vm._s(_vm.$t("account.aci_tx2")))
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "flex flex_5" }, [
                      _c(
                        "div",
                        { staticClass: "col3 col_5 mb20" },
                        [
                          _c("block-select", {
                            attrs: {
                              label: _vm.$t("account.aci_tx2_1"),
                              values: this.dates,
                              disabled_options: _vm.days_disabled,
                              "no-enter-label": true,
                              error: _vm.errors["birthday_day"]
                            },
                            model: {
                              value: _vm.localUser["birthday_day"],
                              callback: function($$v) {
                                _vm.$set(_vm.localUser, "birthday_day", $$v)
                              },
                              expression: "localUser['birthday_day']"
                            }
                          })
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "col5 col_5 mb20" },
                        [
                          _c("block-select", {
                            attrs: {
                              label: _vm.$t("account.aci_tx2_2"),
                              values: _vm.$t("account.aci_tx7"),
                              noEnterLabel: true,
                              error: _vm.errors["birthday_month"]
                            },
                            model: {
                              value: _vm.localUser["birthday_month"],
                              callback: function($$v) {
                                _vm.$set(_vm.localUser, "birthday_month", $$v)
                              },
                              expression: "localUser['birthday_month']"
                            }
                          })
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "col4 col_5 mb20" },
                        [
                          _c("block-select", {
                            attrs: {
                              label: _vm.$t("account.aci_tx2_3"),
                              values: this.years,
                              noEnterLabel: true,
                              error: _vm.errors["birthday_year"]
                            },
                            model: {
                              value: _vm.localUser["birthday_year"],
                              callback: function($$v) {
                                _vm.$set(_vm.localUser, "birthday_year", $$v)
                              },
                              expression: "localUser['birthday_year']"
                            }
                          })
                        ],
                        1
                      )
                    ]),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "mb20" },
                      [
                        _c("block-select", {
                          attrs: {
                            label: _vm.$t("account.aci_tx24"),
                            values: this.params["countries"],
                            multiple: true,
                            error: _vm.errors["countries"]
                          },
                          model: {
                            value: _vm.localUser["countries"],
                            callback: function($$v) {
                              _vm.$set(_vm.localUser, "countries", $$v)
                            },
                            expression: "localUser['countries']"
                          }
                        })
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "vue-slide-up-down",
                      {
                        attrs: {
                          active: Object.keys(this.regions).length !== 0
                        }
                      },
                      [
                        _c(
                          "div",
                          { staticClass: "mb20" },
                          [
                            _c("p", { staticClass: "fz17 mb20" }, [
                              _vm._v(_vm._s(_vm.$t("account.aci_tx26")))
                            ]),
                            _vm._v(" "),
                            _c("block-select", {
                              ref: "region",
                              attrs: {
                                label: _vm.$t("account.aci_tx27"),
                                values: this.regions
                              },
                              model: {
                                value: _vm.localUser["region"],
                                callback: function($$v) {
                                  _vm.$set(_vm.localUser, "region", $$v)
                                },
                                expression: "localUser['region']"
                              }
                            })
                          ],
                          1
                        )
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "mb20" },
                      [
                        _c("block-select", {
                          attrs: {
                            label: _vm.$t("account.aci_tx25"),
                            values: this.params["transports"],
                            multiple: true,
                            group: true,
                            error: _vm.errors["transports"]
                          },
                          model: {
                            value: _vm.localUser["transports"],
                            callback: function($$v) {
                              _vm.$set(_vm.localUser, "transports", $$v)
                            },
                            expression: "localUser['transports']"
                          }
                        })
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "mb20" },
                      [
                        _c(
                          "div",
                          { staticClass: "mb10" },
                          [
                            _c("block-checkbox", {
                              attrs: { label: _vm.$t("account.aci_tx8") },
                              model: {
                                value: _vm.localUser["blogger"],
                                callback: function($$v) {
                                  _vm.$set(_vm.localUser, "blogger", $$v)
                                },
                                expression: "localUser['blogger']"
                              }
                            })
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "vue-slide-up-down",
                          {
                            attrs: {
                              active: !!_vm.localUser["blogger"],
                              name: "slide"
                            }
                          },
                          [
                            _c("block-input", {
                              attrs: {
                                label: _vm.$t("account.aci_tx9"),
                                error: _vm.errors["blog_link"]
                              },
                              model: {
                                value: _vm.localUser["blog_link"],
                                callback: function($$v) {
                                  _vm.$set(_vm.localUser, "blog_link", $$v)
                                },
                                expression: "localUser['blog_link']"
                              }
                            })
                          ],
                          1
                        )
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "mb20" },
                      [
                        _c(
                          "div",
                          { staticClass: "mb10" },
                          [
                            _c("block-checkbox", {
                              attrs: { label: _vm.$t("account.aci_tx11") },
                              model: {
                                value: _vm.localUser["photographer"],
                                callback: function($$v) {
                                  _vm.$set(_vm.localUser, "photographer", $$v)
                                },
                                expression: "localUser['photographer']"
                              }
                            })
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "vue-slide-up-down",
                          {
                            attrs: {
                              active: !!_vm.localUser["photographer"],
                              name: "slide"
                            }
                          },
                          [
                            _c("block-input", {
                              attrs: {
                                label: _vm.$t("account.aci_tx12"),
                                error: _vm.errors["video_link"]
                              },
                              model: {
                                value: _vm.localUser["video_link"],
                                callback: function($$v) {
                                  _vm.$set(_vm.localUser, "video_link", $$v)
                                },
                                expression: "localUser['video_link']"
                              }
                            })
                          ],
                          1
                        )
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "mb20" },
                      [
                        _c("block-checkbox", {
                          attrs: { label: _vm.$t("account.aci_tx13") },
                          model: {
                            value: _vm.localUser["guide"],
                            callback: function($$v) {
                              _vm.$set(_vm.localUser, "guide", $$v)
                            },
                            expression: "localUser['guide']"
                          }
                        })
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "mb20" },
                      [
                        _c("block-input", {
                          attrs: {
                            label: _vm.$t("account.acie_tx1"),
                            textarea: true,
                            error: _vm.errors["about"]
                          },
                          model: {
                            value: _vm.localUser["about"],
                            callback: function($$v) {
                              _vm.$set(_vm.localUser, "about", $$v)
                            },
                            expression: "localUser['about']"
                          }
                        })
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "mb20" },
                      [
                        _c("block-input", {
                          attrs: {
                            label: _vm.$t("account.acie_tx2"),
                            textarea: true,
                            error: _vm.errors["socials"]
                          },
                          model: {
                            value: _vm.localUser["socials"],
                            callback: function($$v) {
                              _vm.$set(_vm.localUser, "socials", $$v)
                            },
                            expression: "localUser['socials']"
                          }
                        })
                      ],
                      1
                    )
                  ],
                  1
                )
              : _vm._e(),
            _vm._v(" "),
            _vm.localUser["type"] == 2
              ? _c("div", [
                  _c(
                    "div",
                    { staticClass: "mb20" },
                    [
                      _c("block-input", {
                        attrs: {
                          label: _vm.$t("account.aci_tx17"),
                          error: _vm.errors["name"]
                        },
                        model: {
                          value: _vm.localUser["name"],
                          callback: function($$v) {
                            _vm.$set(_vm.localUser, "name", $$v)
                          },
                          expression: "localUser['name']"
                        }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "mb20" },
                    [
                      _c("block-input", {
                        attrs: {
                          label: _vm.$t("account.aci_tx18"),
                          error: _vm.errors["code"]
                        },
                        model: {
                          value: _vm.localUser["code"],
                          callback: function($$v) {
                            _vm.$set(_vm.localUser, "code", $$v)
                          },
                          expression: "localUser['code']"
                        }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "mb20" },
                    [
                      _c("block-select", {
                        attrs: {
                          label: _vm.$t("account.aci_tx21"),
                          values: _vm.$t("account.aci_tx20"),
                          error: _vm.errors["specialization"]
                        },
                        model: {
                          value: _vm.localUser["specialization"],
                          callback: function($$v) {
                            _vm.$set(_vm.localUser, "specialization", $$v)
                          },
                          expression: "localUser['specialization']"
                        }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "mb20" },
                    [
                      _c("block-input", {
                        attrs: {
                          label: _vm.$t("account.aci_tx22"),
                          error: _vm.errors["site_link"]
                        },
                        model: {
                          value: _vm.localUser["site_link"],
                          callback: function($$v) {
                            _vm.$set(_vm.localUser, "site_link", $$v)
                          },
                          expression: "localUser['site_link']"
                        }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "mb20" },
                    [
                      _c("block-input", {
                        attrs: {
                          label: _vm.$t("account.crt_lb8"),
                          textarea: true,
                          error: _vm.errors["about"]
                        },
                        model: {
                          value: _vm.localUser["about"],
                          callback: function($$v) {
                            _vm.$set(_vm.localUser, "about", $$v)
                          },
                          expression: "localUser['about']"
                        }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "mb20" },
                    [
                      _c("block-select", {
                        attrs: {
                          label: _vm.$t("account.aci_tx23"),
                          values: this.params["countries"],
                          multiple: true,
                          error: _vm.errors["countries"]
                        },
                        model: {
                          value: _vm.localUser["countries"],
                          callback: function($$v) {
                            _vm.$set(_vm.localUser, "countries", $$v)
                          },
                          expression: "localUser['countries']"
                        }
                      })
                    ],
                    1
                  )
                ])
              : _vm._e()
          ])
        : _c("div", [
            _c(
              "div",
              { staticClass: "mb20" },
              [
                _c("block-select", {
                  attrs: {
                    label: _vm.$t("account.acd_tx4"),
                    values: _vm.$t("account.acd_tx5"),
                    error: _vm.errors["type"]
                  },
                  model: {
                    value: _vm.localUser["type"],
                    callback: function($$v) {
                      _vm.$set(_vm.localUser, "type", $$v)
                    },
                    expression: "localUser['type']"
                  }
                })
              ],
              1
            )
          ])
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "col12" }, [
      _c("button", { staticClass: "btn", on: { click: _vm.save } }, [
        _vm._v(_vm._s(_vm.$t("account.acd_tx3")))
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }