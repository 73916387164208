var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "flex  catalog" }, [
    _c("div", { staticClass: "catalog__filter-shadow" }),
    _vm._v(" "),
    _c("div", { staticClass: "col3 catalog__filter" }, [
      _c("div", { staticClass: "catalog__filter-close" }, [
        _c("svg", [_c("use", { attrs: { "xlink:href": "#ico-close" } })])
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "filter" }, [
        _c(
          "div",
          { staticClass: "filter__wrap mb10" },
          [
            _c("div", { staticClass: "flex1 flex-v-center mb20" }, [
              _c("p", { staticClass: "fz20 fw600 flex-full" }, [
                _vm._v(_vm._s(_vm.$t("text.ct_tx2")))
              ]),
              _vm._v(" "),
              this.clearEnabled
                ? _c(
                    "a",
                    {
                      staticClass: "link link-blue fz14 filter__reset",
                      attrs: { href: this.events_url }
                    },
                    [_vm._v(_vm._s(_vm.$t("text.ct_tx4")))]
                  )
                : _vm._e()
            ]),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "mb20" },
              [
                _c("filter-country", {
                  attrs: {
                    all_countries: this.params["all_countries"],
                    regions: this.params["regions"],
                    refs: "geo"
                  },
                  model: {
                    value: _vm.filter["geo"],
                    callback: function($$v) {
                      _vm.$set(_vm.filter, "geo", $$v)
                    },
                    expression: "filter['geo']"
                  }
                })
              ],
              1
            ),
            _vm._v(" "),
            _c("block-slider", {
              attrs: {
                min: 1,
                max: this.params["distance_max"],
                step: 1,
                title: _vm.$t("text.ct_tx18")
              },
              model: {
                value: _vm.filter["distances"],
                callback: function($$v) {
                  _vm.$set(_vm.filter, "distances", $$v)
                },
                expression: "filter['distances']"
              }
            }),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "mb20" },
              [
                _c("block-input", {
                  attrs: { label: _vm.$t("travel.ntf_cns_tx4") },
                  model: {
                    value: _vm.filter["like"]["name"],
                    callback: function($$v) {
                      _vm.$set(_vm.filter["like"], "name", $$v)
                    },
                    expression: "filter['like']['name']"
                  }
                })
              ],
              1
            ),
            _vm._v(" "),
            _c("div", { staticClass: "mb10" }, [
              _c("div", { staticClass: "fz17 fw600 mb20" }, [
                _vm._v(_vm._s(_vm.$t("event.ct_tx6")))
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "flex flex_5" }, [
                _c(
                  "div",
                  { staticClass: "col6 col_5 mb10" },
                  [
                    _c("block-date-time", {
                      attrs: {
                        formattedValue: _vm.filter["start"]["min"],
                        type: "date",
                        format: "DD.MM.YYYY",
                        value_type: "YYYY-MM-DD",
                        label: _vm.$t("event.ct_tx6_1")
                      },
                      model: {
                        value: _vm.filter["start"]["min"],
                        callback: function($$v) {
                          _vm.$set(_vm.filter["start"], "min", $$v)
                        },
                        expression: "filter['start']['min']"
                      }
                    })
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "col6 col_5 mb10" },
                  [
                    _c("block-date-time", {
                      attrs: {
                        formattedValue: _vm.filter["start"]["max"],
                        type: "date",
                        format: "DD.MM.YYYY",
                        value_type: "YYYY-MM-DD",
                        label: _vm.$t("event.ct_tx6_2")
                      },
                      model: {
                        value: _vm.filter["start"]["max"],
                        callback: function($$v) {
                          _vm.$set(_vm.filter["start"], "max", $$v)
                        },
                        expression: "filter['start']['max']"
                      }
                    })
                  ],
                  1
                )
              ])
            ]),
            _vm._v(" "),
            _c("block-slider", {
              attrs: {
                min: 0,
                max: this.params["length_max"],
                step: 1,
                title: _vm.$t("event.ct_tx7")
              },
              model: {
                value: _vm.filter["lengths"],
                callback: function($$v) {
                  _vm.$set(_vm.filter, "lengths", $$v)
                },
                expression: "filter['lengths']"
              }
            }),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "mb20" },
              [
                _c("block-select", {
                  attrs: {
                    label: _vm.$t("event.target_name"),
                    values: this.params["all_targets"],
                    multiple: ""
                  },
                  model: {
                    value: _vm.filter["targets"],
                    callback: function($$v) {
                      _vm.$set(_vm.filter, "targets", $$v)
                    },
                    expression: "filter['targets']"
                  }
                })
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "mb20" },
              [
                _c("block-select", {
                  attrs: {
                    label: _vm.$t("text.difficult_name"),
                    values: _vm.$t("text.difficult")
                  },
                  model: {
                    value: _vm.filter["same"]["events.difficult"],
                    callback: function($$v) {
                      _vm.$set(_vm.filter["same"], "events.difficult", $$v)
                    },
                    expression: "filter['same']['events.difficult']"
                  }
                })
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { class: [_vm.filter["transports"].length ? "mb10" : "mb20"] },
              [
                _c("block-select", {
                  attrs: {
                    label: _vm.$t("text.transport_name"),
                    values: this.params["all_transports"],
                    group: true,
                    multiple: ""
                  },
                  model: {
                    value: _vm.filter["transports"],
                    callback: function($$v) {
                      _vm.$set(_vm.filter, "transports", $$v)
                    },
                    expression: "filter['transports']"
                  }
                })
              ],
              1
            ),
            _vm._v(" "),
            _vm.filter["transports"].length
              ? _c("div", { staticClass: "mb20" }, [
                  _c(
                    "div",
                    { staticClass: "mb5" },
                    [
                      _c("block-checkbox", {
                        attrs: { label: _vm.$t("event.pt_tx3_1") },
                        model: {
                          value: _vm.filter["similar_transports"],
                          callback: function($$v) {
                            _vm.$set(_vm.filter, "similar_transports", $$v)
                          },
                          expression: "filter['similar_transports']"
                        }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("p", { staticClass: "fz14 lh15 color-gray" }, [
                    _vm._v(_vm._s(_vm.$t("event.pt_tx3_2")))
                  ])
                ])
              : _vm._e(),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "mb20" },
              [
                _c("block-select", {
                  attrs: {
                    label: _vm.$t("event.ct_tx12"),
                    values: _vm.$t("event.ct_tx11")
                  },
                  model: {
                    value: _vm.filter["payment"],
                    callback: function($$v) {
                      _vm.$set(_vm.filter, "payment", $$v)
                    },
                    expression: "filter['payment']"
                  }
                })
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "mb20" },
              [
                _c("block-select", {
                  attrs: {
                    label: _vm.$t("event.ct_tx16"),
                    values: _vm.$t("event.ct_tx15")
                  },
                  model: {
                    value: _vm.filter["same"]["passenger"],
                    callback: function($$v) {
                      _vm.$set(_vm.filter["same"], "passenger", $$v)
                    },
                    expression: "filter['same']['passenger']"
                  }
                })
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "button",
              {
                staticClass: "btn btn_med btn_full filter__btn",
                on: { click: _vm.sendFilter }
              },
              [
                _vm._v(
                  "\n                    " +
                    _vm._s(_vm.$t("text.ct_tx3")) +
                    "\n                "
                )
              ]
            )
          ],
          1
        )
      ])
    ]),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "col9 col12-768" },
      [
        _c("div", { staticClass: "flex flex-v-center mb20" }, [
          _c("div", { staticClass: "col0 flex-full" }, [
            _c("h1", { staticClass: "fz36 fw600" }, [
              _vm._v(_vm._s(_vm.$t("event.title")))
            ])
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "col0 show-768" }, [
            _c("div", { staticClass: "catalog__filter-button btn" }, [
              _c("svg", { staticClass: "mr5" }, [
                _c("use", { attrs: { "xlink:href": "#ico-filter" } })
              ]),
              _vm._v(
                "\n                    " +
                  _vm._s(_vm.$t("text.filter")) +
                  "\n                "
              )
            ])
          ])
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "categ__tabs" }, [
          _c("a", { attrs: { href: "#categ__tab1" } }, [
            _vm._v(_vm._s(_vm.$t("text.tab1")))
          ]),
          _vm._v(" "),
          _c("a", { attrs: { href: "#categ__tab2" } }, [
            _vm._v(_vm._s(_vm.$t("text.tab2")))
          ])
        ]),
        _vm._v(" "),
        _c("div", { attrs: { id: "categ__tab1" } }, [
          !this.was_filtered
            ? _c(
                "div",
                { staticClass: "categ flex flex_5 flex-wrap" },
                _vm._l(_vm.per_page, function(ind) {
                  return _c(
                    "div",
                    { staticClass: "col6 col_5 mb10 col12-768" },
                    [
                      _c("div", { staticClass: "card-border" }, [
                        _c(
                          "div",
                          { staticClass: "mb10" },
                          [
                            _c("vue-skeleton-loader", {
                              attrs: { width: 150, height: 20 }
                            })
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "mb10" },
                          [
                            _c("vue-skeleton-loader", {
                              attrs: { width: 200, height: 16 }
                            })
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "mb10" },
                          [
                            _c("vue-skeleton-loader", {
                              attrs: { width: 300, height: 32 }
                            })
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "mb10" },
                          [
                            _c("vue-skeleton-loader", {
                              attrs: { width: 240, height: 28 }
                            })
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "mb10" },
                          [
                            _c("vue-skeleton-loader", {
                              attrs: { width: 300, height: 25 }
                            })
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          [
                            _c("vue-skeleton-loader", {
                              attrs: { width: 300, height: 28 }
                            })
                          ],
                          1
                        )
                      ])
                    ]
                  )
                }),
                0
              )
            : _c(
                "div",
                { staticClass: "categ flex flex_5 flex-wrap" },
                _vm._l(this.events, function(event) {
                  return _c(
                    "div",
                    {
                      key: event["id"],
                      staticClass: "col6 col_5 mb10 col12-768"
                    },
                    [_c("event-card", { attrs: { event: event } })],
                    1
                  )
                }),
                0
              ),
          _vm._v(" "),
          Object.keys(this.events).length === 0 && this.was_filtered
            ? _c("div", [
                _vm._v(
                  "\n                " +
                    _vm._s(_vm.$t("text.nothing_found")) +
                    "\n            "
                )
              ])
            : _vm._e()
        ]),
        _vm._v(" "),
        _c("div", { attrs: { id: "categ__tab2" } }, [
          _c(
            "div",
            { staticClass: "map__frame relative" },
            [
              _c("l-map", { ref: "myMap" }),
              _vm._v(" "),
              _c("div", {
                staticClass: "travel-list__map",
                attrs: { id: "mapdiv" }
              }),
              _vm._v(" "),
              _c("div", { staticClass: " map__legend" }, [
                _c("div", { staticClass: "mb10" }, [
                  _c(
                    "a",
                    {
                      staticClass:
                        "tooltip-right btn btn_small map__btn js-map-fullscreen",
                      attrs: {
                        "data-title": _vm.$t("event.map.crt_tx6"),
                        href: "#"
                      }
                    },
                    [
                      _c("svg", [
                        _c("use", { attrs: { "xlink:href": "#ico-map-full" } })
                      ]),
                      _vm._v(" "),
                      _c("span", [_vm._v(_vm._s(_vm.$t("event.map.crt_tx6")))])
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "a",
                    {
                      staticClass:
                        "tooltip-right btn btn_small map__btn js-map-window",
                      staticStyle: { display: "none" },
                      attrs: {
                        "data-title": _vm.$t("event.map.crt_tx7"),
                        href: "#"
                      }
                    },
                    [
                      _c("svg", [
                        _c("use", { attrs: { "xlink:href": "#ico-map-small" } })
                      ]),
                      _vm._v(" "),
                      _c("span", [_vm._v(_vm._s(_vm.$t("event.map.crt_tx7")))])
                    ]
                  )
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "mb10" }, [
                  this.show_start_markers
                    ? _c(
                        "a",
                        {
                          staticClass: "tooltip-right btn btn_small map__btn",
                          attrs: {
                            "data-title": _vm.$t("event.map.lst_tx4"),
                            href: "#"
                          },
                          on: {
                            click: function($event) {
                              $event.preventDefault()
                              return _vm.hideStart.apply(null, arguments)
                            }
                          }
                        },
                        [
                          _c("svg", [
                            _c("use", { attrs: { "xlink:href": "#ico-start" } })
                          ]),
                          _vm._v(" "),
                          _c("span", [
                            _vm._v(_vm._s(_vm.$t("event.map.lst_tx4_2")))
                          ])
                        ]
                      )
                    : _c(
                        "a",
                        {
                          staticClass:
                            "tooltip-right btn btn_small btn_off map__btn",
                          attrs: {
                            "data-title": _vm.$t("event.map.lst_tx4"),
                            href: "#"
                          },
                          on: {
                            click: function($event) {
                              $event.preventDefault()
                              return _vm.showStart.apply(null, arguments)
                            }
                          }
                        },
                        [
                          _c("svg", [
                            _c("use", { attrs: { "xlink:href": "#ico-start" } })
                          ]),
                          _vm._v(" "),
                          _c("span", [
                            _vm._v(_vm._s(_vm.$t("event.map.lst_tx4_1")))
                          ])
                        ]
                      )
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "mb10" }, [
                  this.show_end_markers
                    ? _c(
                        "a",
                        {
                          staticClass: "tooltip-right btn btn_small map__btn",
                          attrs: {
                            "data-title": _vm.$t("event.map.lst_tx5"),
                            href: "#"
                          },
                          on: {
                            click: function($event) {
                              $event.preventDefault()
                              return _vm.hideEnd.apply(null, arguments)
                            }
                          }
                        },
                        [
                          _c("svg", [
                            _c("use", { attrs: { "xlink:href": "#ico-end" } })
                          ]),
                          _vm._v(" "),
                          _c("span", [
                            _vm._v(_vm._s(_vm.$t("event.map.lst_tx5_2")))
                          ])
                        ]
                      )
                    : _c(
                        "a",
                        {
                          staticClass:
                            "tooltip-right btn btn_small btn_off map__btn",
                          attrs: {
                            "data-title": _vm.$t("event.map.lst_tx5"),
                            href: "#"
                          },
                          on: {
                            click: function($event) {
                              $event.preventDefault()
                              return _vm.showEnd.apply(null, arguments)
                            }
                          }
                        },
                        [
                          _c("svg", [
                            _c("use", { attrs: { "xlink:href": "#ico-end" } })
                          ]),
                          _vm._v(" "),
                          _c("span", [
                            _vm._v(_vm._s(_vm.$t("event.map.lst_tx5_1")))
                          ])
                        ]
                      )
                ])
              ])
            ],
            1
          )
        ]),
        _vm._v(" "),
        _c("pagination", {
          attrs: {
            current: parseInt(_vm.filter["page"]),
            max: parseInt(_vm.max_pages)
          },
          on: { switch_page: _vm.switchPage }
        })
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }