module.exports = {
    "txt1": "Старт",
    "txt1_1": "Дата старта не указана",
    "txt1_2": "Началось",
    "txt1_3": "назад",
    "txt2": "на маршруте",
    "txt3": "Черновик",
    "txt4": "Участие платное",
    "txt5": "Возможна регистрация пассажиров",
    "target_name": "Цели",
    "prt_tx6": "начало через",
    "prt_tx6_1": "дн.",
    "prt_tx6_2": "ч.",
    "states": [
        {
            "id": 1,
            "name": "Активное"
        }, {
            "id": 2,
            "name": "Отменено по просьбе пользователя"
        }, {
            "id": 3,
            "name": "Отменено администратором"
        }
    ],
    "evn_tx0": "Выберите аватар",
    "type": [
        {
            "id": 1,
            "name": "Открытое для всех"
        }, {
            "id": 2,
            "name": "Открытое с подтверждением"
        }, {
            "id": 3,
            "name": "Закрытое"
        }
    ],
    "crt_tx2": "Тип события",
    "crt_tx1_1": "<span class='color-dark'>Открытое для всех</span> - просматривать событие и участвовать могут все желающие<br><span class='color-dark'>Открытое с подтверждением</span> - все видят событие, однако участвовать могут только с разрешения организатора<br><span class='color-dark'>Закрытое</span> - видеть и участвовать в событии могут только участники, которым организатор отправит приглашение",
    "crt_tx4": "Особенности (завершение при)",
    "crt_tx5": [
        {
            "id": 1,
            "name": "прибытии в конечную точку"
        }, {
            "id": 2,
            "name": "возращении на точку отправления"
        }, {
            "id": 3,
            "name": "прохождении круга (нескольких кругов)"
        }, {
            "id": 4,
            "name": "событие в одном месте"
        }, {
            "id": 5,
            "name": "другое"
        }
    ],
    "crt_tx6": "Дата и время начала",
    "crt_tx7": "Продолжительность",
    "crt_tx9": "Дней/Часов",
    "crt_tx8": [
        {
            "id": 1,
            "name": "Часов"
        }, {
            "id": 2,
            "name": "Дней"
        }
    ],
    "crt_tx10": "Сложность маршрута",
    "crt_tx10_1": "У события вы можете выбрать другое значение",
    "crt_tx11": "Транспорт предоставляется организатором события",
    "crt_tx12": [
        {
            "id": 1,
            "name": "Да"
        }, {
            "id": 2,
            "name": "Нет"
        }, {
            "id": 3,
            "name": "Частично"
        }
    ],
    "crt_tx15": "Количество экипажей",
    "crt_tx15_1": "Количество участников",
    "crt_tx16": "Минимальное",
    "crt_tx17": "Оптимальное",
    "crt_tx18": "Максимальное",
    "crt_tx19": "Количество людей в одном экипаже (для многоместных транспортных средств)",
    "crt_tx20": "Более 1 человека в экипаже",
    "crt_tx21": "Возможна регистрация пассажиров",
    "crt_tx22": "Количество людей в экипаже определяет организатор события",
    "crt_tx23": "Если разрешить регистрацию пассажиров, то каждый участник с транспортом при подаче заявки должен будет указывать количество человек в его транспортном средстве.<br> Участники без транспортных средств смогут подавать заявки до заполнения всех экипажей (максимальное количество человек в экипаже).",
    "crt_tx24": "Если не отмечено, то каждый участник сам принимает заявки пассажиров",
    "crt_tx25": [
        {
            "id": 1,
            "name": "Свое"
        }, {
            "id": 2,
            "name": "Предоставляется организатором (включено в стоимость)"
        }, {
            "id": 3,
            "name": "Предоставляется организатором (за дополнительную плату)"
        }
    ],
    "crt_tx26": "Снаряжение",
    "crt_tx27": "Питание",
    "crt_tx28": "Сопровождение гидом (экскурсоводом)",
    "crt_tx29": [
        {
            "id": 1,
            "name": "Не требуется"
        }, {
            "id": 2,
            "name": "Предоставляется организатором (включено в стоимость)"
        }, {
            "id": 3,
            "name": "Предоставляется организатором (за дополнительную плату)"
        }
    ],
    "crt_tx30": "Проживание/места ночлега",
    "crt_tx31": [
        {
            "id": 1,
            "name": "Не требуется"
        }, {
            "id": 2,
            "name": "Свое"
        }, {
            "id": 3,
            "name": "Предоставляется организатором (включено в стоимость)"
        }, {
            "id": 4,
            "name": "Предоставляется организатором (за дополнительную плату)"
        }
    ],
    "crt_tx32": "Условия проживания",
    "crt_tx33": [
        {
            "id": 1,
            "name": "Под открытым небом"
        }, {
            "id": 2,
            "name": "В транспорте"
        }, {
            "id": 3,
            "name": "Палатки"
        }, {
            "id": 4,
            "name": "Гостиница"
        }, {
            "id": 5,
            "name": "Гостевой дом"
        }, {
            "id": 6,
            "name": "Арендованное жилье"
        }, {
            "id": 7,
            "name": "Иное"
        }
    ],
    "crt_tx34": "Минимальный уровень подоговки",
    "crt_tx35": [
        {
            "id": 1,
            "name": "Без подготовки"
        }, {
            "id": 2,
            "name": "Начальный"
        }, {
            "id": 3,
            "name": "Средний"
        }, {
            "id": 4,
            "name": "Высокий"
        }, {
            "id": 5,
            "name": "Эксперт"
        }
    ],
    "crt_tx36": "Требования к транспортному средству",
    "crt_tx37": "Требования к экипировке",
    "crt_tx38": "Иные требования",
    "crt_tx39": "Стоимость участия",
    "crt_tx40": "Валюта",
    "crt_tx41": "Участие платное",
    "crt_tx42": "Порядок оплаты взноса",
    "crt_tx42_1": "Цена участия",
    "crt_tx43": "Как добраться до отправной точки",
    "crt_tx44": [
        {
            "id": 1,
            "name": "Не требуется"
        }, {
            "id": 2,
            "name": "Участники добираются самостоятельно"
        }, {
            "id": 3,
            "name": "Предоставляется организатором (включено в стоимость)"
        }, {
            "id": 4,
            "name": "Предоставляется организатором (за дополнительную плату)"
        }
    ],
    "crt_tx45": "Дополнительная информация",
    "crt_tx46": "Напишите в этом поле все, что не охвачено другими вопросами анкеты",
    "crt_tx47": "Опубликовать событие",
    "crt_tx47_1": "Сохранить изменения",
    "crt_tx48": "как у маршрута",
    "crt_tx49": "Серым цветом выделен транспорт, который автор маршрута не указал в качестве подходящего. Но вы можете выбрать их, если считаете, что они могут пройти данный маршрут.",
    "crt_tx50": "Часовой пояс",
    "crt_tx51": "Название",
    "crt_btn1": "При сохранении черновика вы сможете вернуться к редактированию события позже. Найти сохраненные черновики можно в личном кабинете",
    "crt_btn1_1": "Вы можете перенести событие в черновики и опубликовать его позже. Найти сохраненные черновики можно в личном кабинете",
    "crt_btn2": "После создания события оно будет опубликовано для всех пользователей сайта",
    "crt_btn2_1": "Вы можете внести изменения, пока нет ни одного участника",
    "create": "Создать событие",
    "delete": "Удалить событие?",

    "ct_tx6": "Дата старта",
    "ct_tx6_1": "С",
    "ct_tx6_2": "По",
    "ct_tx7": "Продолжительность (дней)",
    "ct_tx12": "Стоимость",
    "ct_tx11": [
        {
            "id": 1,
            "name": "Бесплатное"
        }, {
            "id": 2,
            "name": "Платное"
        },
    ],
    "ct_tx16": "Регистрация пассажиров",
    "ct_tx15": [
        {
            "id": 0,
            "name": "Невозможна"
        }, {
            "id": 1,
            "name": "Возможна"
        },
    ],
    "title": "События",
    "pt_tx1": "старт",
    "pt_tx2": "Расстояние, км",
    "pt_tx3": "Транспорт",
    "pt_tx3_1": "Искать по типу поверхности",
    "pt_tx3_2": "Искать события, в которых автор не указал этот транспорт, но он подходит по типу поверхности",
    "pt_tx4": "финиш"
};
