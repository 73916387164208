var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "flex flex-wrap-768" }, [
      _c(
        "div",
        { staticClass: "col6 mb10 col12-768" },
        [_c("travel-card", { attrs: { travel: _vm.params["travel"] } })],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "col6 mb10 col12-768" },
        [
          _c("travel-map", {
            attrs: {
              distance: _vm.params["travel"]["distance"],
              points: _vm.params["travel"]["points_parsed"]
            }
          })
        ],
        1
      )
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "flex mb20 flex-wrap-768" }, [
      _c(
        "div",
        { staticClass: "col6 mb20 col12-768" },
        [
          _c(
            "div",
            { staticClass: "mb10" },
            [
              _c("p", { staticClass: "fz17 fw600 mb10" }, [
                _vm._v(_vm._s(_vm.$t("event.evn_tx0")))
              ]),
              _vm._v(" "),
              _c("block-multi-photo", {
                attrs: { files: _vm.eventLocal["images"], max: 1 },
                model: {
                  value: _vm.eventLocal["images"],
                  callback: function($$v) {
                    _vm.$set(_vm.eventLocal, "images", $$v)
                  },
                  expression: "eventLocal['images']"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "mb20" },
            [
              _c("block-input", {
                attrs: {
                  label: _vm.$t("event.crt_tx51"),
                  error: _vm.errors["name"]
                },
                model: {
                  value: _vm.eventLocal["name"],
                  callback: function($$v) {
                    _vm.$set(_vm.eventLocal, "name", $$v)
                  },
                  expression: "eventLocal['name']"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c("div", { staticClass: "mb20" }, [
            _c(
              "div",
              { staticClass: "mb10" },
              [
                _c("block-select", {
                  attrs: {
                    label: _vm.$t("event.crt_tx2"),
                    values: _vm.$t("event.type"),
                    error: _vm.errors["type"]
                  },
                  model: {
                    value: _vm.eventLocal["type"],
                    callback: function($$v) {
                      _vm.$set(_vm.eventLocal, "type", $$v)
                    },
                    expression: "eventLocal['type']"
                  }
                })
              ],
              1
            ),
            _vm._v(" "),
            _c("p", {
              staticClass: "fz17 lh14 fw600 mb10 color-gray",
              domProps: { innerHTML: _vm._s(this.$t("event.crt_tx1_1")) }
            })
          ]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "mb20" },
            [
              _c("block-select", {
                attrs: {
                  label: _vm.$t("event.crt_tx4"),
                  values: _vm.$t("event.crt_tx5"),
                  error: _vm.errors["features"]
                },
                model: {
                  value: _vm.eventLocal["features"],
                  callback: function($$v) {
                    _vm.$set(_vm.eventLocal, "features", $$v)
                  },
                  expression: "eventLocal['features']"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "mb20" },
            [
              _c("block-select", {
                attrs: {
                  label: _vm.$t("event.target_name"),
                  values: this.params["all_targets"],
                  multiple: "",
                  error: _vm.errors["targets"]
                },
                model: {
                  value: _vm.eventLocal["targets"],
                  callback: function($$v) {
                    _vm.$set(_vm.eventLocal, "targets", $$v)
                  },
                  expression: "eventLocal['targets']"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "mb20" },
            [
              _c("block-date-time", {
                attrs: {
                  error: _vm.errors["start"],
                  formattedValue: _vm.eventLocal["start_formatted_edit"],
                  type: "datetime",
                  format: "DD.MM.YYYY HH:mm",
                  value_type: "YYYY-MM-DD HH:mm",
                  label: _vm.$t("event.crt_tx6")
                },
                model: {
                  value: _vm.eventLocal["start"],
                  callback: function($$v) {
                    _vm.$set(_vm.eventLocal, "start", $$v)
                  },
                  expression: "eventLocal['start']"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "mb20" },
            [
              _c("block-select", {
                attrs: {
                  label: _vm.$t("event.crt_tx50"),
                  values: _vm.timezones,
                  error: _vm.errors["timezone"]
                },
                model: {
                  value: _vm.eventLocal["timezone"],
                  callback: function($$v) {
                    _vm.$set(_vm.eventLocal, "timezone", $$v)
                  },
                  expression: "eventLocal['timezone']"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c("div", { staticClass: "flex flex_5" }, [
            _c(
              "div",
              { staticClass: "mb20 col6 col_5" },
              [
                _c("block-input", {
                  attrs: {
                    label: _vm.$t("event.crt_tx7"),
                    error: _vm.errors["length_count"],
                    input_class: "input-mask-3"
                  },
                  model: {
                    value: _vm.eventLocal["length_count"],
                    callback: function($$v) {
                      _vm.$set(_vm.eventLocal, "length_count", $$v)
                    },
                    expression: "eventLocal['length_count']"
                  }
                })
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "mb20 col6 col_5" },
              [
                _c("block-select", {
                  attrs: {
                    label: _vm.$t("event.crt_tx9"),
                    values: _vm.$t("event.crt_tx8"),
                    error: _vm.errors["length_unit"]
                  },
                  model: {
                    value: _vm.eventLocal["length_unit"],
                    callback: function($$v) {
                      _vm.$set(_vm.eventLocal, "length_unit", $$v)
                    },
                    expression: "eventLocal['length_unit']"
                  }
                })
              ],
              1
            )
          ]),
          _vm._v(" "),
          _c("p", { staticClass: "fz17 fw600 mb20" }, [
            _vm._v(
              _vm._s(_vm.$t("event.crt_tx10")) +
                ":\n                " +
                _vm._s(
                  _vm.getNameById(
                    _vm.params["travel"]["difficult"],
                    _vm.$t("text.difficult")
                  )
                ) +
                ".\n                " +
                _vm._s(_vm.$t("event.crt_tx10_1"))
            )
          ]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "mb20" },
            [
              _c("block-select", {
                attrs: {
                  label: _vm.$t("text.difficult_name"),
                  values: _vm.$t("text.difficult")
                },
                model: {
                  value: _vm.eventLocal["difficult"],
                  callback: function($$v) {
                    _vm.$set(_vm.eventLocal, "difficult", $$v)
                  },
                  expression: "eventLocal['difficult']"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c("div", { staticClass: "tourist-block" }, [
            _vm.show_transport
              ? _c("p", { staticClass: "fz17 fw600 mb20" }, [
                  _vm._v(_vm._s(_vm.$t("event.crt_tx15_1")))
                ])
              : _c("p", { staticClass: "fz17 fw600 mb20" }, [
                  _vm._v(_vm._s(_vm.$t("event.crt_tx15")))
                ])
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "flex flex_5 flex-wrap-550" }, [
            _c(
              "div",
              { staticClass: "col4 col_5 mb20 col12-550" },
              [
                _c("block-input", {
                  attrs: {
                    label: _vm.$t("event.crt_tx16"),
                    input_class: "input-mask-3",
                    error: _vm.errors["min_participant"]
                  },
                  model: {
                    value: _vm.eventLocal["min_participant"],
                    callback: function($$v) {
                      _vm.$set(_vm.eventLocal, "min_participant", $$v)
                    },
                    expression: "eventLocal['min_participant']"
                  }
                })
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "col4 col_5 mb20 col12-550" },
              [
                _c("block-input", {
                  attrs: {
                    label: _vm.$t("event.crt_tx17"),
                    input_class: "input-mask-3",
                    error: _vm.errors["opt_participant"]
                  },
                  model: {
                    value: _vm.eventLocal["opt_participant"],
                    callback: function($$v) {
                      _vm.$set(_vm.eventLocal, "opt_participant", $$v)
                    },
                    expression: "eventLocal['opt_participant']"
                  }
                })
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "col4 col_5 mb20 col12-550" },
              [
                _c("block-input", {
                  attrs: {
                    label: _vm.$t("event.crt_tx18"),
                    input_class: "input-mask-3",
                    error: _vm.errors["max_participant"]
                  },
                  model: {
                    value: _vm.eventLocal["max_participant"],
                    callback: function($$v) {
                      _vm.$set(_vm.eventLocal, "max_participant", $$v)
                    },
                    expression: "eventLocal['max_participant']"
                  }
                })
              ],
              1
            )
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "mb20" }, [
            _c(
              "div",
              { staticClass: "mb10" },
              [
                _c("block-select", {
                  attrs: {
                    label: _vm.$t("text.transport_name"),
                    values: this.params["all_transports"],
                    group: true,
                    error: _vm.errors["transports"],
                    enabled: _vm.params["travel"]["transports"],
                    multiple: ""
                  },
                  model: {
                    value: _vm.eventLocal["transports"],
                    callback: function($$v) {
                      _vm.$set(_vm.eventLocal, "transports", $$v)
                    },
                    expression: "eventLocal['transports']"
                  }
                })
              ],
              1
            ),
            _vm._v(" "),
            _c("p", { staticClass: "fz16 lh14 mb20 color-gray" }, [
              _vm._v(_vm._s(_vm.$t("event.crt_tx49")))
            ])
          ]),
          _vm._v(" "),
          _c(
            "vue-slide-up-down",
            { attrs: { active: _vm.show_transport, name: "slide" } },
            [
              _c(
                "div",
                { staticClass: "transport-block" },
                [
                  _c(
                    "div",
                    { staticClass: "mb20" },
                    [
                      _c("block-select", {
                        attrs: {
                          label: _vm.$t("event.crt_tx11"),
                          values: _vm.$t("event.crt_tx12"),
                          error: _vm.errors["transport_provided"]
                        },
                        model: {
                          value: _vm.eventLocal["transport_provided"],
                          callback: function($$v) {
                            _vm.$set(_vm.eventLocal, "transport_provided", $$v)
                          },
                          expression: "eventLocal['transport_provided']"
                        }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "mb20" },
                    [
                      _c("block-input", {
                        attrs: {
                          label: _vm.$t("event.crt_tx36"),
                          error: _vm.errors["transport_requirements"],
                          textarea: true
                        },
                        model: {
                          value: _vm.eventLocal["transport_requirements"],
                          callback: function($$v) {
                            _vm.$set(
                              _vm.eventLocal,
                              "transport_requirements",
                              $$v
                            )
                          },
                          expression: "eventLocal['transport_requirements']"
                        }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "mb20" },
                    [
                      _c("block-checkbox", {
                        attrs: { label: _vm.$t("event.crt_tx20") },
                        model: {
                          value: _vm.eventLocal["teams"],
                          callback: function($$v) {
                            _vm.$set(_vm.eventLocal, "teams", $$v)
                          },
                          expression: "eventLocal['teams']"
                        }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "vue-slide-up-down",
                    {
                      attrs: {
                        active: !!_vm.eventLocal["teams"],
                        name: "slide"
                      }
                    },
                    [
                      _c(
                        "div",
                        [
                          _c("div", { staticClass: "pb20" }, [
                            _c(
                              "div",
                              { staticClass: "mb10" },
                              [
                                _c("block-checkbox", {
                                  attrs: { label: _vm.$t("event.crt_tx21") },
                                  model: {
                                    value: _vm.eventLocal["passenger"],
                                    callback: function($$v) {
                                      _vm.$set(_vm.eventLocal, "passenger", $$v)
                                    },
                                    expression: "eventLocal['passenger']"
                                  }
                                })
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c("p", {
                              staticClass: "fz16 lh14 color-gray",
                              domProps: {
                                innerHTML: _vm._s(_vm.$t("event.crt_tx23"))
                              }
                            })
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "pb20" }, [
                            _c(
                              "div",
                              { staticClass: "mb10" },
                              [
                                _c("block-checkbox", {
                                  attrs: { label: _vm.$t("event.crt_tx22") },
                                  model: {
                                    value: _vm.eventLocal["fixed_team"],
                                    callback: function($$v) {
                                      _vm.$set(
                                        _vm.eventLocal,
                                        "fixed_team",
                                        $$v
                                      )
                                    },
                                    expression: "eventLocal['fixed_team']"
                                  }
                                })
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c("p", {
                              staticClass: "fz16 lh14 color-gray",
                              domProps: {
                                innerHTML: _vm._s(_vm.$t("event.crt_tx24"))
                              }
                            })
                          ]),
                          _vm._v(" "),
                          _c(
                            "vue-slide-up-down",
                            {
                              attrs: {
                                active: !!_vm.eventLocal["fixed_team"],
                                name: "slide"
                              }
                            },
                            [
                              _c("div", [
                                _c("p", { staticClass: "fz17 fw600 mb10" }, [
                                  _vm._v(_vm._s(_vm.$t("event.crt_tx19")))
                                ]),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  { staticClass: "flex flex_5 flex-wrap-550" },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticClass: "col4 col_5 mb20 col12-550"
                                      },
                                      [
                                        _c("block-input", {
                                          attrs: {
                                            label: _vm.$t("event.crt_tx16"),
                                            input_class: "input-mask-3",
                                            error: _vm.errors["min_team"]
                                          },
                                          model: {
                                            value: _vm.eventLocal["min_team"],
                                            callback: function($$v) {
                                              _vm.$set(
                                                _vm.eventLocal,
                                                "min_team",
                                                $$v
                                              )
                                            },
                                            expression: "eventLocal['min_team']"
                                          }
                                        })
                                      ],
                                      1
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "div",
                                      {
                                        staticClass: "col4 col_5 mb20 col12-550"
                                      },
                                      [
                                        _c("block-input", {
                                          attrs: {
                                            label: _vm.$t("event.crt_tx17"),
                                            input_class: "input-mask-3",
                                            error: _vm.errors["opt_team"]
                                          },
                                          model: {
                                            value: _vm.eventLocal["opt_team"],
                                            callback: function($$v) {
                                              _vm.$set(
                                                _vm.eventLocal,
                                                "opt_team",
                                                $$v
                                              )
                                            },
                                            expression: "eventLocal['opt_team']"
                                          }
                                        })
                                      ],
                                      1
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "div",
                                      {
                                        staticClass: "col4 col_5 mb20 col12-550"
                                      },
                                      [
                                        _c("block-input", {
                                          attrs: {
                                            label: _vm.$t("event.crt_tx18"),
                                            input_class: "input-mask-3",
                                            error: _vm.errors["max_team"]
                                          },
                                          model: {
                                            value: _vm.eventLocal["max_team"],
                                            callback: function($$v) {
                                              _vm.$set(
                                                _vm.eventLocal,
                                                "max_team",
                                                $$v
                                              )
                                            },
                                            expression: "eventLocal['max_team']"
                                          }
                                        })
                                      ],
                                      1
                                    )
                                  ]
                                )
                              ])
                            ]
                          )
                        ],
                        1
                      )
                    ]
                  )
                ],
                1
              )
            ]
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "col6 mb20 col12-768" },
        [
          _c(
            "div",
            { staticClass: "mb20" },
            [
              _c("block-select", {
                attrs: {
                  label: _vm.$t("event.crt_tx26"),
                  values: _vm.$t("event.crt_tx25"),
                  error: _vm.errors["equipment"]
                },
                model: {
                  value: _vm.eventLocal["equipment"],
                  callback: function($$v) {
                    _vm.$set(_vm.eventLocal, "equipment", $$v)
                  },
                  expression: "eventLocal['equipment']"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "mb20" },
            [
              _c("block-select", {
                attrs: {
                  label: _vm.$t("event.crt_tx27"),
                  values: _vm.$t("event.crt_tx25"),
                  error: _vm.errors["food"]
                },
                model: {
                  value: _vm.eventLocal["food"],
                  callback: function($$v) {
                    _vm.$set(_vm.eventLocal, "food", $$v)
                  },
                  expression: "eventLocal['food']"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "mb20" },
            [
              _c("block-select", {
                attrs: {
                  label: _vm.$t("event.crt_tx28"),
                  values: _vm.$t("event.crt_tx29"),
                  error: _vm.errors["escort"],
                  disabled_options:
                    _vm.params["travel"]["escort"] == 1 ? [1] : [100]
                },
                model: {
                  value: _vm.eventLocal["escort"],
                  callback: function($$v) {
                    _vm.$set(_vm.eventLocal, "escort", $$v)
                  },
                  expression: "eventLocal['escort']"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "mb20" },
            [
              _c("block-select", {
                attrs: {
                  label: _vm.$t("event.crt_tx30"),
                  values: _vm.$t("event.crt_tx31"),
                  error: _vm.errors["lodging"]
                },
                model: {
                  value: _vm.eventLocal["lodging"],
                  callback: function($$v) {
                    _vm.$set(_vm.eventLocal, "lodging", $$v)
                  },
                  expression: "eventLocal['lodging']"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "vue-slide-up-down",
            {
              attrs: { active: _vm.eventLocal["lodging"] != 1, name: "slide" }
            },
            [
              _c(
                "div",
                { staticClass: "mb20" },
                [
                  _c("block-select", {
                    attrs: {
                      label: _vm.$t("event.crt_tx32"),
                      values: _vm.$t("event.crt_tx33"),
                      error: _vm.errors["accommodations"]
                    },
                    model: {
                      value: _vm.eventLocal["accommodations"],
                      callback: function($$v) {
                        _vm.$set(_vm.eventLocal, "accommodations", $$v)
                      },
                      expression: "eventLocal['accommodations']"
                    }
                  })
                ],
                1
              )
            ]
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "mb20" },
            [
              _c("block-select", {
                attrs: {
                  label: _vm.$t("event.crt_tx34"),
                  values: _vm.$t("event.crt_tx35"),
                  error: _vm.errors["min_preparation"]
                },
                model: {
                  value: _vm.eventLocal["min_preparation"],
                  callback: function($$v) {
                    _vm.$set(_vm.eventLocal, "min_preparation", $$v)
                  },
                  expression: "eventLocal['min_preparation']"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "mb20" },
            [
              _c("block-input", {
                attrs: {
                  label: _vm.$t("event.crt_tx37"),
                  error: _vm.errors["equipment_requirements"],
                  textarea: true
                },
                model: {
                  value: _vm.eventLocal["equipment_requirements"],
                  callback: function($$v) {
                    _vm.$set(_vm.eventLocal, "equipment_requirements", $$v)
                  },
                  expression: "eventLocal['equipment_requirements']"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "mb20" },
            [
              _c("block-input", {
                attrs: {
                  label: _vm.$t("event.crt_tx38"),
                  error: _vm.errors["other_requirements"],
                  textarea: true
                },
                model: {
                  value: _vm.eventLocal["other_requirements"],
                  callback: function($$v) {
                    _vm.$set(_vm.eventLocal, "other_requirements", $$v)
                  },
                  expression: "eventLocal['other_requirements']"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            [
              _c(
                "div",
                { staticClass: "mb20" },
                [
                  _c("block-checkbox", {
                    attrs: { label: _vm.$t("event.crt_tx41") },
                    model: {
                      value: _vm.eventLocal["payment_checkbox"],
                      callback: function($$v) {
                        _vm.$set(_vm.eventLocal, "payment_checkbox", $$v)
                      },
                      expression: "eventLocal['payment_checkbox']"
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "vue-slide-up-down",
                {
                  attrs: {
                    active:
                      !!_vm.eventLocal["payment"] ||
                      !!_vm.eventLocal["payment_checkbox"],
                    name: "slide"
                  }
                },
                [
                  _c("div", [
                    _c("div", { staticClass: "flex flex_5 flex-wrap" }, [
                      _c(
                        "div",
                        { staticClass: "col6 col_5 mb20" },
                        [
                          _c("block-input", {
                            attrs: {
                              label: _vm.$t("event.crt_tx39"),
                              error: _vm.errors["payment"],
                              input_class: "input-mask-7"
                            },
                            model: {
                              value: _vm.eventLocal["payment"],
                              callback: function($$v) {
                                _vm.$set(_vm.eventLocal, "payment", $$v)
                              },
                              expression: "eventLocal['payment']"
                            }
                          })
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "col6 col_5 mb20" },
                        [
                          _c("block-select", {
                            attrs: {
                              label: _vm.$t("event.crt_tx40"),
                              values: _vm.params["all_currencies"],
                              error: _vm.errors["currency"]
                            },
                            model: {
                              value: _vm.eventLocal["currency"],
                              callback: function($$v) {
                                _vm.$set(_vm.eventLocal, "currency", $$v)
                              },
                              expression: "eventLocal['currency']"
                            }
                          })
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "col12 col_5 mb20" },
                        [
                          _c("block-input", {
                            attrs: {
                              label: _vm.$t("event.crt_tx42"),
                              error: _vm.errors["payment_requirements"],
                              textarea: true
                            },
                            model: {
                              value: _vm.eventLocal["payment_requirements"],
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.eventLocal,
                                  "payment_requirements",
                                  $$v
                                )
                              },
                              expression: "eventLocal['payment_requirements']"
                            }
                          })
                        ],
                        1
                      )
                    ])
                  ])
                ]
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "mb20" },
            [
              _c("block-select", {
                attrs: {
                  label: _vm.$t("event.crt_tx43"),
                  values: _vm.$t("event.crt_tx44"),
                  error: _vm.errors["transfer"]
                },
                model: {
                  value: _vm.eventLocal["transfer"],
                  callback: function($$v) {
                    _vm.$set(_vm.eventLocal, "transfer", $$v)
                  },
                  expression: "eventLocal['transfer']"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c("div", { staticClass: "mb20" }, [
            _c(
              "div",
              { staticClass: "mb10" },
              [
                _c("block-input", {
                  attrs: {
                    label: _vm.$t("event.crt_tx45"),
                    error: _vm.errors["description"],
                    textarea: true
                  },
                  model: {
                    value: _vm.eventLocal["description"],
                    callback: function($$v) {
                      _vm.$set(_vm.eventLocal, "description", $$v)
                    },
                    expression: "eventLocal['description']"
                  }
                })
              ],
              1
            ),
            _vm._v(" "),
            _c("p", { staticClass: "fz16 lh14 mb10 color-gray" }, [
              _vm._v(_vm._s(_vm.$t("event.crt_tx46")))
            ])
          ])
        ],
        1
      )
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "form-width mb10" }, [
      _vm.eventLocal["id"]
        ? _c("p", { staticClass: "fz16 lh14 mb10 color-gray" }, [
            _vm._v(_vm._s(_vm.$t("event.crt_btn1")))
          ])
        : _c("p", { staticClass: "fz16 lh14 mb10 color-gray" }, [
            _vm._v(_vm._s(_vm.$t("event.crt_btn1_1")))
          ]),
      _vm._v(" "),
      _vm.eventLocal["id"]
        ? _c("p", { staticClass: "fz16 lh14 mb10 color-gray" }, [
            _vm._v(_vm._s(_vm.$t("event.crt_btn2_1")))
          ])
        : _c("p", { staticClass: "fz16 lh14 mb10 color-gray" }, [
            _vm._v(_vm._s(_vm.$t("event.crt_btn2")))
          ])
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "mb80" }, [
      _c("div", { staticClass: "flex1 mb20" }, [
        _vm.eventLocal["id"]
          ? _c(
              "button",
              {
                staticClass: "btn mr10",
                on: {
                  click: function($event) {
                    $event.preventDefault()
                    return _vm.prepareToSave.apply(null, arguments)
                  }
                }
              },
              [
                _vm._v(
                  "\n                " +
                    _vm._s(_vm.$t("text.create_edit")) +
                    "\n            "
                )
              ]
            )
          : _c(
              "button",
              {
                staticClass: "btn mr10",
                on: {
                  click: function($event) {
                    $event.preventDefault()
                    return _vm.prepareToSave.apply(null, arguments)
                  }
                }
              },
              [_vm._v(_vm._s(_vm.$t("event.create")))]
            ),
        _vm._v(" "),
        !_vm.eventLocal["id"]
          ? _c(
              "button",
              {
                staticClass: "btn btn_trs mr10",
                on: {
                  click: function($event) {
                    $event.preventDefault()
                    return _vm.saveDraft.apply(null, arguments)
                  }
                }
              },
              [
                _vm._v(
                  "\n                " +
                    _vm._s(_vm.$t("text.draft")) +
                    "\n            "
                )
              ]
            )
          : _vm._e(),
        _vm._v(" "),
        _vm.eventLocal["id"] && _vm.eventLocal["draft"] == 0
          ? _c(
              "button",
              {
                staticClass: "btn btn_trs mr10",
                on: {
                  click: function($event) {
                    $event.preventDefault()
                    return _vm.saveDraft.apply(null, arguments)
                  }
                }
              },
              [_vm._v(_vm._s(_vm.$t("text.draft_move")) + "\n            ")]
            )
          : _vm._e(),
        _vm._v(" "),
        _vm.eventLocal["id"] && _vm.eventLocal["draft"] == 1
          ? _c(
              "button",
              {
                staticClass: "btn btn_trs mr10",
                on: {
                  click: function($event) {
                    $event.preventDefault()
                    return _vm.saveClean.apply(null, arguments)
                  }
                }
              },
              [_vm._v(_vm._s(_vm.$t("text.draft_publish")) + "\n            ")]
            )
          : _vm._e(),
        _vm._v(" "),
        _vm.eventLocal["id"]
          ? _c(
              "button",
              {
                staticClass: "btn btn_red",
                on: {
                  click: function($event) {
                    $event.preventDefault()
                    return _vm.destroy.apply(null, arguments)
                  }
                }
              },
              [_vm._v(_vm._s(_vm.$t("text.delete")) + "\n            ")]
            )
          : _vm._e()
      ]),
      _vm._v(" "),
      _c("div", [
        _vm.eventLocal["id"] && _vm.eventLocal["draft"] == 0
          ? _c("a", { staticClass: "btn", attrs: { href: _vm.url_show } }, [
              _vm._v(
                "\n                " +
                  _vm._s(_vm.$t("text.go_to_card")) +
                  "\n            "
              )
            ])
          : _vm._e()
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }