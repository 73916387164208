var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "div",
        { staticClass: "mb20" },
        [
          _c("block-select", {
            ref: "country",
            attrs: { label: _vm.$t("txt1"), values: this.all_countries },
            model: {
              value: _vm.country,
              callback: function($$v) {
                _vm.country = $$v
              },
              expression: "country"
            }
          })
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "vue-slide-up-down",
        { attrs: { active: Object.keys(this.regions).length !== 0 } },
        [
          _c(
            "div",
            { staticClass: "mb20" },
            [
              _c("block-select", {
                ref: "region",
                attrs: { label: _vm.$t("txt2"), values: this.regions },
                model: {
                  value: _vm.region,
                  callback: function($$v) {
                    _vm.region = $$v
                  },
                  expression: "region"
                }
              })
            ],
            1
          )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }