var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      { staticClass: "mb20" },
      [
        _c("block-review", {
          attrs: {
            label: _vm.$t("rv_tx2"),
            identifier: "rating_travel",
            error: _vm.errors["rating_travel"]
          },
          model: {
            value: _vm.form["rating_travel"],
            callback: function($$v) {
              _vm.$set(_vm.form, "rating_travel", $$v)
            },
            expression: "form['rating_travel']"
          }
        })
      ],
      1
    ),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "mb20" },
      [
        _c("block-review", {
          attrs: {
            label: _vm.$t("rv_tx3"),
            identifier: "rating_event",
            error: _vm.errors["rating_event"]
          },
          model: {
            value: _vm.form["rating_event"],
            callback: function($$v) {
              _vm.$set(_vm.form, "rating_event", $$v)
            },
            expression: "form['rating_event']"
          }
        })
      ],
      1
    ),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "mb20" },
      [
        _c("block-review", {
          attrs: {
            label: _vm.$t("rv_tx4"),
            identifier: "rating_user",
            error: _vm.errors["rating_user"]
          },
          model: {
            value: _vm.form["rating_user"],
            callback: function($$v) {
              _vm.$set(_vm.form, "rating_user", $$v)
            },
            expression: "form['rating_user']"
          }
        })
      ],
      1
    ),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "mb20" },
      [
        _c("p", { staticClass: "fz17 lh15 mb10" }, [
          _vm._v(_vm._s(_vm.$t("rv_tx6")))
        ]),
        _vm._v(" "),
        _c("block-multi-photo", {
          attrs: { files: [], max: 10 },
          model: {
            value: _vm.form["images"],
            callback: function($$v) {
              _vm.$set(_vm.form, "images", $$v)
            },
            expression: "form['images']"
          }
        })
      ],
      1
    ),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "mb20" },
      [
        _c("block-input", {
          attrs: {
            label: _vm.$t("rv_tx1"),
            error: _vm.errors["review"],
            textarea: true
          },
          model: {
            value: _vm.form["review"],
            callback: function($$v) {
              _vm.$set(_vm.form, "review", $$v)
            },
            expression: "form['review']"
          }
        })
      ],
      1
    ),
    _vm._v(" "),
    _c("button", { staticClass: "btn", on: { click: _vm.save } }, [
      _vm._v(_vm._s(_vm.$t("rv_tx5")))
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }