var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "relative map" }, [
    _c("div", { staticClass: "map__frame" }, [
      _c("div", { staticClass: "travel-form__map", attrs: { id: "mapdiv" } }),
      _vm._v(" "),
      _c("div", { staticClass: "map__legend" }, [
        _c("p", { staticClass: "map__legend-distance fz16 mb10" }, [
          _c("span", { staticClass: "color-gray" }, [
            _vm._v(_vm._s(_vm.$t("crt_tx14")) + ":")
          ]),
          _vm._v(" "),
          _c("span", { staticClass: "js-field-distance fw600" }, [
            _vm._v(_vm._s(this.distance))
          ])
        ]),
        _vm._v(" "),
        _c("div", [
          _c(
            "button",
            {
              staticClass:
                "btn btn_small map__btn js-map-fullscreen tooltip-right",
              attrs: { "data-title": _vm.$t("map.crt_tx6") }
            },
            [
              _c("svg", [_c("use", { attrs: { "xlink:href": "#ico-full" } })]),
              _vm._v(" "),
              _c("span", [_vm._v(_vm._s(_vm.$t("map.crt_tx6")))])
            ]
          ),
          _vm._v(" "),
          _c(
            "button",
            {
              staticClass: "tooltip-right btn btn_small map__btn js-map-window",
              staticStyle: { display: "none" },
              attrs: { "data-title": _vm.$t("map.crt_tx7") }
            },
            [
              _c("svg", [
                _c("use", { attrs: { "xlink:href": "#ico-smallsize" } })
              ]),
              _vm._v(" "),
              _c("span", [_vm._v(_vm._s(_vm.$t("map.crt_tx7")))])
            ]
          )
        ])
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }