module.exports = {
    "prt_tx1": "Участвовать",
    "prt_tx1_1": "Хочу участвовать!",
    "prt_tx2": "Отправить",
    "prt_tx3": [
        {
            "id": 1,
            "name": "На своем транспорте"
        }, {
            "id": 2,
            "name": "В качестве пассажира"
        },
    ],
    "prt_tx4": "Комментарий",
    "prt_tx5": "Отправить заявку на участие в событии",
    "prt_tx5_1": "Внимание! Вы являетесь автором события, но обязательно должны отправить заявку на участие!",
    "prt_tx7": "дней",
    "prt_tx8": "ВНИМАНИЕ! Администрация ресурса не осуществляет сбор средств за участие в событии! Не перевеодите оплату на банковскую карту физ.лиц. Оплачивайте счет от юридического лица, либо наличными непосредственно перед началом события.",
    "prt_tx9": "Количество свободных мест",
    "prt_tx10": "Если вы можете взять пассажиров - укажите вместимость вашего транспорта",
    "prt_tx11": "Вы уже отправляли заявку",
    "prt_tx11_1": "На данный момент нет свободных пассажирских мест. Попробуйте позже",
    "prt_tx11_2": "Уже зарегистрировалось максимальное число участников",
    "prt_tx12": "Ваша заявка успешно отправлена!",
    "prt_tx13_1": "Вы являетесь организатором события",
    "prt_tx13": "Вы являетесь участником события",
    "prt_tx14": "Ваша заявка на участие на рассмотрении",
    "prt_tx15": "Аннулировать заявку? Вы не сможете подать ее повторно!",
    "prt_tx18": "ВНИМАНИЕ! Если вы отмените заявку, не сможете подать ее повторно, и больше не будете являться участником события",
    "prt_tx19": "Отменить участие",
    "prt_tx20": "Вы отменили участие в событии. Повторная подача заявки невозможна",
    "prt_tx21": "Организатор отклонил вашу заявку.",
    "prt_tx22": "Дата регистрации",
    "prt_tx23": "Имя",
    "prt_tx24": "Комментарий",
    "prt_tx25": "Отправленные заявки",
    "prt_tx26": "Статус",
    "prt_tx27": "Вы уверены, что хотите отклонить заявку?",
    "prt_tx28": "Да, отклонить",
    "prt_tx29": "Принять",
    "prt_tx30": "Отклонить",
    "prt_sts19": [
        {
            "id": 1,
            "name": "Организатор"
        }, {
            "id": 2,
            "name": "Требует подтверждения"
        },{
            "id": 3,
            "name": "Подтверждено"
        },{
            "id": 9,
            "name": "Отозвана"
        },{
            "id": 10,
            "name": "Отклонена организатором"
        },
    ],
    "prt_tx35": "Участие",
    "prt_tx37": "Макс.пассажиров",
    "prt_tx38": "Утвержденных участников",
    "prt_tx39": "Из них пассажирами",
    "prt_tx40": "из",
    "prt_tx40_1": "возможных",
    "prt_tx41": "Из них водителями",
    "prt_tx45": "Не более",
    "prt_tx46": "- ограничение установлено организатором события",
    "prt_tx47": "Что нужно взять с собой",
    "prt_tx47_1": "Отметьте то, что вы готовы взять на себя",
    "prt_tx48": "Добавить пункт",
    "prt_tx49": "Заполните текст",
    "ms_tx1": "Обсуждение события",
    "ms_tx2": "Сообщение",
    "ms_tx3": "Отправить",
    "ms_tx5": "Пока никто ничего не написал. Станьте первым!"
}
