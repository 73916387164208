var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "flex flex_5 flex-wrap", attrs: { id: "uploadImagesList" } },
    [
      _vm._l(this.old_images, function(file) {
        return _c("div", { staticClass: "mb10 col3 col_5 col6-550" }, [
          _c(
            "div",
            {
              staticClass: "photo1__wrap photo1__wrap-main",
              style: { "background-image": "url(" + file["thumb1"] + ")" }
            },
            [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.old_images,
                    expression: "old_images"
                  }
                ],
                attrs: { type: "hidden" },
                domProps: { value: _vm.old_images },
                on: {
                  input: function($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.old_images = $event.target.value
                  }
                }
              }),
              _vm._v(" "),
              _c(
                "button",
                {
                  staticClass: "photo1__remove",
                  on: {
                    click: function($event) {
                      $event.preventDefault()
                      return _vm.removeOldItem(file["id"])
                    }
                  }
                },
                [_vm._v("×")]
              )
            ]
          )
        ])
      }),
      _vm._v(" "),
      _vm._l(this.files, function(file) {
        return _c("div", { staticClass: "mb10 col3 col_5 col6-550" }, [
          _c(
            "div",
            {
              staticClass: "photo1__wrap photo1__wrap-main",
              style: { "background-image": "url(" + file["thumb1"] + ")" }
            },
            [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.old_images,
                    expression: "old_images"
                  }
                ],
                attrs: { type: "hidden" },
                domProps: { value: _vm.old_images },
                on: {
                  input: function($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.old_images = $event.target.value
                  }
                }
              }),
              _vm._v(" "),
              _c(
                "button",
                {
                  staticClass: "photo1__remove",
                  on: {
                    click: function($event) {
                      $event.preventDefault()
                      return _vm.removeItem(file["id"])
                    }
                  }
                },
                [_vm._v("×")]
              )
            ]
          )
        ])
      }),
      _vm._v(" "),
      _vm.totalImages < _vm.max
        ? _c(
            "label",
            { staticClass: "col3 col_5 mb10 photo1__wrap-add col6-550" },
            [
              _c("input", {
                ref: "file",
                attrs: { type: "file", id: "addImages", multiple: "" },
                on: {
                  change: function($event) {
                    return _vm.uploadFile(0)
                  }
                }
              }),
              _vm._v(" "),
              _c("span", { staticClass: "photo1__empty" }, [
                _c("span", [
                  _c(
                    "svg",
                    { attrs: { width: "2.25rem", height: "2.25rem" } },
                    [_c("use", { attrs: { "xlink:href": "#ico-noimage" } })]
                  ),
                  _vm._v(" "),
                  _c("span", { staticClass: "color-dark fz16 fw800" }, [
                    _vm._v(_vm._s(_vm.$t("crt_btn4")))
                  ])
                ])
              ])
            ]
          )
        : _vm._e()
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }