module.exports = {
    'acd_tx5': [
        {
            "id": 1,
            "name": "Путешественник"
        }, {
            "id": 2,
            "name": "Организатор туров"
        }
    ],
    "acd_tx3": "Сохранить",
    "acd_tx4": "Тип пользователя",
    "ava": "Выберите аватар",
    "acd_tx6": "Вы уверены? В будущем будет невозможно изменить тип пользователя",
    "acd_tx1": "Оставьте пустым, чтобы не менять",
    "aci_tx1": "Псевдоним",
    "aci_tx2": "Дата рождения",
    "aci_tx2_1": "День",
    "aci_tx2_2": "Месяц",
    "aci_tx2_3": "Год",
    "aci_tx6": "Пол",
    "aci_tx5": [
        {
            "id": 1,
            "name": "Мужской"
        }, {
            "id": 2,
            "name": "Женский"
        }, {
            "id": 3,
            "name": "Другое"
        }
    ],

    "aci_tx7": [
        {
            "id": 1,
            "name": "января"
        }, {
            "id": 2,
            "name": "февраля"
        }, {
            "id": 3,
            "name": "марта"
        }, {
            "id": 4,
            "name": "апреля"
        }, {
            "id": 5,
            "name": "мая"
        }, {
            "id": 6,
            "name": "июня"
        }, {
            "id": 7,
            "name": "июля"
        }, {
            "id": 8,
            "name": "августа"
        }, {
            "id": 9,
            "name": "сентября"
        }, {
            "id": 10,
            "name": "октября"
        }, {
            "id": 11,
            "name": "ноября"
        }, {
            "id": 12,
            "name": "декабря"
        },
    ],
    "aci_tx8": "Я блогер",
    "aci_tx9": "Ссылка на блог",
    "aci_tx11": "Я фотограф/видеооператор",
    "aci_tx12": "Ссылка на портфолио",
    "aci_tx13": "Могу быть гидом, проводником",
    "acie_tx1": "Немного о себе",
    "acie_tx2": "Соцсети (каждая ссылка с новой строки)",
    "aci_tx17": "Название",
    "aci_tx18": "ИНН (код организации)",
    "aci_tx20": [
        {
            "id": 1,
            "name": "Турагенство"
        }, {
            "id": 2,
            "name": "Органы власти и администрации"
        }, {
            "id": 3,
            "name": "Спортивный клуб"
        },{
            "id": 4,
            "name": "Туристический клуб"
        },{
            "id": 5,
            "name": "Экологическая организация"
        },{
            "id": 6,
            "name": "Научно-исследовательская организация"
        },{
            "id": 7,
            "name": "Образовательная организация"
        },
    ],
    "aci_tx21": "Специализация",
    "aci_tx22": "Ссылка на сайт",
    "aci_tx23": "Страны, в которых работаете",
    "aci_tx24": "Страны, в которых путешествуете",
    "aci_tx25": "Какой транспорт используете",
    "aci_tx26": "Если указана только одна страна, можно уточнить регион",
    "aci_tx27": "Регион",
    "crt_lb8": "Краткое описание"
}
