var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("p", { staticClass: "fz17 lh15" }, [_vm._v(_vm._s(this.label))]),
    _vm._v(" "),
    _c("div", { staticClass: "rating" }, [
      _c("div", {
        staticClass: "rating__fill",
        style: { width: (this.number / 5) * 100 + "%" }
      }),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "rating__number fz16 flex1 flex-v-center flex-center" },
        [_vm._v(_vm._s(this.number) + "\n        ")]
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }