var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.max > 1
    ? _c("nav", { staticClass: "pagination" }, [
        _c("div", { staticClass: "flex flex-justify" }, [
          _c("div", { staticClass: "col0" }, [
            _c(
              "a",
              {
                staticClass: "pagination__link",
                on: {
                  click: function($event) {
                    return _vm.switchPage(_vm.current_page - 1)
                  }
                }
              },
              [_vm._v("\n                Назад\n            ")]
            )
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "flex1 col0" }, [
            _vm.current_page !== 1
              ? _c("div", { staticClass: "col0" }, [
                  _c(
                    "p",
                    {
                      staticClass: "pagination__link",
                      on: {
                        click: function($event) {
                          return _vm.switchPage(1)
                        }
                      }
                    },
                    [_vm._v("\n                    1\n                ")]
                  )
                ])
              : _vm._e(),
            _vm._v(" "),
            _vm.current_page - 1 && _vm.current_page - 1 !== 1
              ? _c("div", { staticClass: "col0" }, [
                  _c(
                    "p",
                    {
                      staticClass: "pagination__link",
                      on: {
                        click: function($event) {
                          return _vm.switchPage(_vm.current_page - 1)
                        }
                      }
                    },
                    [
                      _vm._v(
                        "\n                    " +
                          _vm._s(_vm.current_page - 1) +
                          "\n                "
                      )
                    ]
                  )
                ])
              : _vm._e(),
            _vm._v(" "),
            _c("div", { staticClass: "col0" }, [
              _c(
                "p",
                {
                  staticClass: "pagination__link pagination__link_current",
                  on: {
                    click: function($event) {
                      return _vm.switchPage(_vm.current_page)
                    }
                  }
                },
                [
                  _vm._v(
                    "\n                    " +
                      _vm._s(_vm.current_page) +
                      "\n                "
                  )
                ]
              )
            ]),
            _vm._v(" "),
            _vm.current_page + 1 <= _vm.max && _vm.current_page + 1 !== _vm.max
              ? _c("div", { staticClass: "col0" }, [
                  _c(
                    "p",
                    {
                      staticClass: "pagination__link",
                      on: {
                        click: function($event) {
                          return _vm.switchPage(_vm.current_page + 1)
                        }
                      }
                    },
                    [
                      _vm._v(
                        "\n                    " +
                          _vm._s(_vm.current_page + 1) +
                          "\n                "
                      )
                    ]
                  )
                ])
              : _vm._e(),
            _vm._v(" "),
            _vm.current_page !== _vm.max
              ? _c("div", { staticClass: "col0" }, [
                  _c(
                    "p",
                    {
                      staticClass: "pagination__link",
                      on: {
                        click: function($event) {
                          return _vm.switchPage(_vm.max)
                        }
                      }
                    },
                    [
                      _vm._v(
                        "\n                    " +
                          _vm._s(_vm.max) +
                          "\n                "
                      )
                    ]
                  )
                ])
              : _vm._e()
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "col0" }, [
            _c(
              "p",
              {
                staticClass: "pagination__link",
                on: {
                  click: function($event) {
                    return _vm.switchPage(_vm.current_page + 1)
                  }
                }
              },
              [_vm._v("\n                Вперед\n            ")]
            )
          ])
        ])
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }