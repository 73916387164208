var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "mb20" }, [
      _c("p", { staticClass: "fz17 fw600" }, [_vm._v(_vm._s(this.label))]),
      _vm._v(" "),
      this.errorLocal && this.errorLocal.length
        ? _c("div", { staticClass: "checkbox-group__error" }, [
            _vm._v(_vm._s(this.errorLocal[0]) + "\n        ")
          ])
        : _vm._e()
    ]),
    _vm._v(" "),
    _c(
      "div",
      {
        staticClass: "checkbox-group mb20 pb20 bb1 trvl1__checkbox",
        class: [
          this.errorLocal && this.errorLocal.length
            ? "checkbox-group_error"
            : ""
        ]
      },
      [
        _c("div", { staticClass: "flex1" }, [
          _c(
            "div",
            { staticClass: "col6" },
            _vm._l(this.all_transports, function(transport_group, i) {
              return i < 2
                ? _c(
                    "div",
                    { staticClass: "mb20" },
                    _vm._l(transport_group["items"], function(transport) {
                      return _c("div", [
                        _vm.recommended.indexOf(transport["id"]) >= 0
                          ? _c("label", { staticClass: "st-checkbox mb5" }, [
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.valueLocal,
                                    expression: "valueLocal"
                                  }
                                ],
                                attrs: { type: "checkbox" },
                                domProps: {
                                  value: transport["id"],
                                  checked: Array.isArray(_vm.valueLocal)
                                    ? _vm._i(_vm.valueLocal, transport["id"]) >
                                      -1
                                    : _vm.valueLocal
                                },
                                on: {
                                  change: function($event) {
                                    var $$a = _vm.valueLocal,
                                      $$el = $event.target,
                                      $$c = $$el.checked ? true : false
                                    if (Array.isArray($$a)) {
                                      var $$v = transport["id"],
                                        $$i = _vm._i($$a, $$v)
                                      if ($$el.checked) {
                                        $$i < 0 &&
                                          (_vm.valueLocal = $$a.concat([$$v]))
                                      } else {
                                        $$i > -1 &&
                                          (_vm.valueLocal = $$a
                                            .slice(0, $$i)
                                            .concat($$a.slice($$i + 1)))
                                      }
                                    } else {
                                      _vm.valueLocal = $$c
                                    }
                                  }
                                }
                              }),
                              _vm._v(" "),
                              _c("span", { staticClass: "st-checkbox__dot" }),
                              _vm._v(" "),
                              _c("span", {
                                staticClass: "trvl1__ico mr5",
                                domProps: {
                                  innerHTML: _vm._s(transport["svg"])
                                }
                              }),
                              _vm._v(" "),
                              _c("span", { staticClass: "fz17" }, [
                                _vm._v(_vm._s(transport["name"]))
                              ])
                            ])
                          : _vm._e()
                      ])
                    }),
                    0
                  )
                : _vm._e()
            }),
            0
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "col6" },
            _vm._l(this.all_transports, function(transport_group, i) {
              return i >= 2
                ? _c(
                    "div",
                    { staticClass: "mb20" },
                    _vm._l(transport_group["items"], function(transport) {
                      return _c("div", [
                        _vm.recommended.indexOf(transport["id"]) >= 0
                          ? _c("label", { staticClass: "st-checkbox mb5" }, [
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.valueLocal,
                                    expression: "valueLocal"
                                  }
                                ],
                                attrs: { type: "checkbox" },
                                domProps: {
                                  value: transport["id"],
                                  checked: Array.isArray(_vm.valueLocal)
                                    ? _vm._i(_vm.valueLocal, transport["id"]) >
                                      -1
                                    : _vm.valueLocal
                                },
                                on: {
                                  change: function($event) {
                                    var $$a = _vm.valueLocal,
                                      $$el = $event.target,
                                      $$c = $$el.checked ? true : false
                                    if (Array.isArray($$a)) {
                                      var $$v = transport["id"],
                                        $$i = _vm._i($$a, $$v)
                                      if ($$el.checked) {
                                        $$i < 0 &&
                                          (_vm.valueLocal = $$a.concat([$$v]))
                                      } else {
                                        $$i > -1 &&
                                          (_vm.valueLocal = $$a
                                            .slice(0, $$i)
                                            .concat($$a.slice($$i + 1)))
                                      }
                                    } else {
                                      _vm.valueLocal = $$c
                                    }
                                  }
                                }
                              }),
                              _vm._v(" "),
                              _c("span", { staticClass: "st-checkbox__dot" }),
                              _vm._v(" "),
                              _c("span", {
                                staticClass: "trvl1__ico mr5",
                                domProps: {
                                  innerHTML: _vm._s(transport["svg"])
                                }
                              }),
                              _vm._v(" "),
                              _c("span", { staticClass: "fz17" }, [
                                _vm._v(_vm._s(transport["name"]))
                              ])
                            ])
                          : _vm._e()
                      ])
                    }),
                    0
                  )
                : _vm._e()
            }),
            0
          )
        ])
      ]
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }