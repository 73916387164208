var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("label", { staticClass: "st-checkbox" }, [
      _c("input", {
        directives: [
          {
            name: "model",
            rawName: "v-model",
            value: _vm.valueLocal,
            expression: "valueLocal"
          }
        ],
        staticClass: "input-checkbox",
        attrs: { type: "checkbox" },
        domProps: {
          checked: Array.isArray(_vm.valueLocal)
            ? _vm._i(_vm.valueLocal, null) > -1
            : _vm.valueLocal
        },
        on: {
          change: function($event) {
            var $$a = _vm.valueLocal,
              $$el = $event.target,
              $$c = $$el.checked ? true : false
            if (Array.isArray($$a)) {
              var $$v = null,
                $$i = _vm._i($$a, $$v)
              if ($$el.checked) {
                $$i < 0 && (_vm.valueLocal = $$a.concat([$$v]))
              } else {
                $$i > -1 &&
                  (_vm.valueLocal = $$a
                    .slice(0, $$i)
                    .concat($$a.slice($$i + 1)))
              }
            } else {
              _vm.valueLocal = $$c
            }
          }
        }
      }),
      _vm._v(" "),
      _c("span", { staticClass: "st-checkbox__dot" }),
      _vm._v(" "),
      _c("span", {
        staticClass: "fz17",
        domProps: { innerHTML: _vm._s(_vm.label) }
      })
    ]),
    _vm._v(" "),
    this.errorLocal
      ? _c("div", { staticClass: "st-input1__error" }, [
          _vm._v(_vm._s(this.errorLocal[0]))
        ])
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }