import { render, staticRenderFns } from "./EventCardSmall.vue?vue&type=template&id=a266369a&scoped=true&"
import script from "./EventCardSmall.vue?vue&type=script&lang=js&"
export * from "./EventCardSmall.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a266369a",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/var/www/tourism.local/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('a266369a')) {
      api.createRecord('a266369a', component.options)
    } else {
      api.reload('a266369a', component.options)
    }
    module.hot.accept("./EventCardSmall.vue?vue&type=template&id=a266369a&scoped=true&", function () {
      api.rerender('a266369a', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "resources/front/js/vue/Event/EventCardSmall.vue"
export default component.exports