var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.group
    ? _c(
        "select",
        { attrs: { multiple: _vm.multiple } },
        [
          _vm._l(_vm.localOptions, function(group) {
            return _vm.enabled && _vm.enabled.length
              ? _c(
                  "optgroup",
                  { attrs: { label: group["name"] } },
                  _vm._l(group.items, function(item, index) {
                    return _vm.enabled.indexOf(item["id"]) >= 0
                      ? _c("option", { domProps: { value: item["id"] } }, [
                          _vm._v(_vm._s(item["name"]) + "\n        ")
                        ])
                      : _vm._e()
                  }),
                  0
                )
              : _vm._e()
          }),
          _vm._v(" "),
          _vm._l(_vm.localOptions, function(group) {
            return _c(
              "optgroup",
              { attrs: { label: group["name"] } },
              _vm._l(group.items, function(item, index) {
                return !_vm.enabled ||
                  _vm.enabled.indexOf(parseInt(item["id"])) == -1
                  ? _c(
                      "option",
                      {
                        class: [
                          _vm.enabled && _vm.enabled.length
                            ? "select1__disabled"
                            : ""
                        ],
                        domProps: { value: item["id"] }
                      },
                      [_vm._v(_vm._s(item["name"]) + "\n        ")]
                    )
                  : _vm._e()
              }),
              0
            )
          })
        ],
        2
      )
    : _c(
        "select",
        { attrs: { multiple: _vm.multiple, disabled: _vm.disabled } },
        _vm._l(_vm.localOptions, function(option, index) {
          return _c(
            "option",
            {
              attrs: {
                disabled:
                  _vm.disabled_options &&
                  _vm.disabled_options.length &&
                  _vm.disabled_options.indexOf(parseInt(index)) >= 0
              },
              domProps: { value: option[_vm.trackBy] }
            },
            [_vm._v("\n        " + _vm._s(option[_vm.label]) + "\n    ")]
          )
        }),
        0
      )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }