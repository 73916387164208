var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      {
        staticClass: "relative form-item form-item_select",
        class: [
          this.errorLocal && this.errorLocal.length
            ? "form-item_select_error"
            : ""
        ]
      },
      [
        _vm.group
          ? _c(
              "multiselect",
              {
                attrs: {
                  options: _vm.values,
                  "group-select": _vm.group,
                  multiple: _vm.multiple,
                  "track-by": "id",
                  label: "name",
                  placeholder: " ",
                  selectLabel: _vm.$t("txt1"),
                  selectGroupLabel: _vm.$t("txt2"),
                  deselectLabel: _vm.$t("txt3"),
                  deselectGroupLabel: _vm.$t("txt4"),
                  selectedLabel: _vm.$t("txt5"),
                  "group-label": "name",
                  "group-values": "items"
                },
                on: { input: _vm.changeValue, select: _vm.checkRecommended },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "option",
                      fn: function(props) {
                        return [
                          _c(
                            "div",
                            {
                              staticClass: "multiselect__opt-inner",
                              class: {
                                "multiselect__opt-inner_not-recommend":
                                  props.option.not_recommended
                              }
                            },
                            [
                              props.option.$isLabel
                                ? _c("span", [
                                    _vm._v(_vm._s(props.option.$groupLabel))
                                  ])
                                : _vm._e(),
                              _vm._v(" "),
                              _c("span", {
                                staticClass: "multiselect__svg",
                                domProps: {
                                  innerHTML: _vm._s(props.option.svg)
                                }
                              }),
                              _vm._v(" "),
                              _c("span", [_vm._v(_vm._s(props.option.name))])
                            ]
                          )
                        ]
                      }
                    }
                  ],
                  null,
                  false,
                  494189948
                ),
                model: {
                  value: _vm.selected,
                  callback: function($$v) {
                    _vm.selected = $$v
                  },
                  expression: "selected"
                }
              },
              [
                _c("span", { attrs: { slot: "noResult" }, slot: "noResult" }, [
                  _vm._v(_vm._s(_vm.$t("txt6")))
                ])
              ]
            )
          : _c(
              "multiselect",
              {
                attrs: {
                  options: _vm.values,
                  multiple: _vm.multiple,
                  "track-by": "id",
                  placeholder: " ",
                  selectLabel: _vm.noEnterLabel ? "" : _vm.$t("txt1"),
                  deselectLabel: _vm.noEnterLabel ? "" : _vm.$t("txt3"),
                  selectedLabel: _vm.noEnterLabel ? "" : _vm.$t("txt5"),
                  label: "name"
                },
                on: { input: _vm.changeValue },
                scopedSlots: _vm._u([
                  {
                    key: "option",
                    fn: function(props) {
                      return [
                        _c("div", { staticClass: "multiselect__opt-inner" }, [
                          _c("span", [_vm._v(_vm._s(props.option.name))])
                        ])
                      ]
                    }
                  }
                ]),
                model: {
                  value: _vm.selected,
                  callback: function($$v) {
                    _vm.selected = $$v
                  },
                  expression: "selected"
                }
              },
              [
                _c("span", { attrs: { slot: "noResult" }, slot: "noResult" }, [
                  _vm._v(_vm._s(_vm.$t("txt6")))
                ])
              ]
            ),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "label-abs",
            class: [this.label_top ? "label-abs_top" : ""]
          },
          [_vm._v("\n            " + _vm._s(this.label) + "\n        ")]
        ),
        _vm._v(" "),
        this.label_top
          ? _c(
              "div",
              {
                staticClass: "form-item__clear",
                on: { click: _vm.clearValue }
              },
              [
                _c("svg", [
                  _c("use", { attrs: { "xlink:href": "#ico-close" } })
                ])
              ]
            )
          : _vm._e()
      ],
      1
    ),
    _vm._v(" "),
    this.errorLocal
      ? _c("div", { staticClass: "form-item_select__error" }, [
          _vm._v(_vm._s(this.errorLocal[0]))
        ])
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }