var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "st-rating" }, [
      _c("p", { staticClass: "fz17 lh15" }, [_vm._v(_vm._s(_vm.label))]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "flex1 flex-v-center" },
        [
          _vm._l(this.marks, function(i) {
            return [
              _c("label", { attrs: { for: "rating" + _vm.identifier + i } }, [
                _c(
                  "svg",
                  {
                    attrs: {
                      xmlns: "http://www.w3.org/2000/svg",
                      height: "1.2rem",
                      viewBox: "0 0 24 24",
                      width: "1.2rem"
                    }
                  },
                  [
                    _c("path", { attrs: { d: "M0 0h24v24H0z", fill: "none" } }),
                    _vm._v(" "),
                    _c("path", { attrs: { d: "M0 0h24v24H0z", fill: "none" } }),
                    _vm._v(" "),
                    _c("path", {
                      attrs: {
                        d:
                          "M12 17.27L18.18 21l-1.64-7.03L22 9.24l-7.19-.61L12 2 9.19 8.63 2 9.24l5.46 4.73L5.82 21z"
                      }
                    })
                  ]
                )
              ]),
              _vm._v(" "),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.valueLocal,
                    expression: "valueLocal"
                  }
                ],
                attrs: { type: "radio", id: "rating" + _vm.identifier + i },
                domProps: { value: i, checked: _vm._q(_vm.valueLocal, i) },
                on: {
                  change: [
                    function($event) {
                      _vm.valueLocal = i
                    },
                    _vm.emitValue
                  ]
                }
              })
            ]
          })
        ],
        2
      ),
      _vm._v(" "),
      this.errorLocal
        ? _c("div", { staticClass: "st-input1__error" }, [
            _vm._v(_vm._s(this.errorLocal[0]))
          ])
        : _vm._e()
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }