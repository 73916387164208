var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "form-item form-item_checkbox",
      class: [
        this.errorLocal && this.errorLocal.length ? "checkbox-group__error" : ""
      ]
    },
    [
      _c("div", { staticClass: "mb10" }, [
        _c("p", { staticClass: "fz17 fw600" }, [_vm._v(_vm._s(this.label))]),
        _vm._v(" "),
        this.errorLocal && this.errorLocal.length
          ? _c("div", { staticClass: "checkbox-group__error" }, [
              _vm._v(_vm._s(this.errorLocal[0]))
            ])
          : _vm._e()
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "flex1 mb10" }, [
        _c("div", { staticClass: "flex-full" }, [
          _vm.selected.length < 24
            ? _c(
                "div",
                {
                  staticClass: "link link-blue fz14",
                  on: { click: _vm.selectAll }
                },
                [_vm._v(_vm._s(_vm.$t("ct_tx5")))]
              )
            : _vm._e()
        ]),
        _vm._v(" "),
        _c("div", [
          _vm.selected.length > 0
            ? _c(
                "div",
                {
                  staticClass: "link link-blue fz14",
                  on: { click: _vm.clear }
                },
                [_vm._v(_vm._s(_vm.$t("text.clear")))]
              )
            : _vm._e()
        ])
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "flex1 flex-wrap form-item__checkbox" },
        _vm._l(12, function(i) {
          return _c(
            "div",
            { staticClass: "relative form-item__checkbox-wrap" },
            [
              _c("p", { staticClass: "form-item__checkbox-name fz14" }, [
                _vm._v(_vm._s(_vm.$t("travel.availabilities")[i - 1]["name"]))
              ]),
              _vm._v(" "),
              _vm._l(2, function(j) {
                return _c("div", {
                  staticClass: "form-item__checkbox-elem",
                  class: [
                    _vm.selected.indexOf(i + "_" + j) > -1
                      ? "form-item__checkbox-elem_selected"
                      : ""
                  ],
                  attrs: { "data-index": i + "_" + j },
                  on: { click: _vm.changeArray }
                })
              })
            ],
            2
          )
        }),
        0
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }