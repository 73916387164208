var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "flex flex-wrap" }, [
      _c(
        "div",
        { staticClass: "col6 col12-768 mb20" },
        [
          _c("travel-edit-map", {
            model: {
              value: _vm.geo_info,
              callback: function($$v) {
                _vm.geo_info = $$v
              },
              expression: "geo_info"
            }
          }),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "mb20 show-550" },
            [
              _c("block-input", {
                attrs: { label: _vm.$t("crt_tx16"), error: _vm.errors["name"] },
                model: {
                  value: _vm.travelLocal["name"],
                  callback: function($$v) {
                    _vm.$set(_vm.travelLocal, "name", $$v)
                  },
                  expression: "travelLocal['name']"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c("div", { staticClass: "mb40" }, [
            _c(
              "div",
              { staticClass: "mb20" },
              [
                _c("block-calendar", {
                  attrs: {
                    label: _vm.$t("crt_tx17"),
                    error: _vm.errors["availability"],
                    selected: _vm.travelLocal["availability_parsed"]
                  },
                  model: {
                    value: _vm.travelLocal["availability"],
                    callback: function($$v) {
                      _vm.$set(_vm.travelLocal, "availability", $$v)
                    },
                    expression: "travelLocal['availability']"
                  }
                })
              ],
              1
            ),
            _vm._v(" "),
            _c("div", { staticClass: "flex1 flex-v-center mb10" }, [
              _c("div", { staticClass: "flex1 flex-v-center mr10" }, [
                _c("div", { staticClass: "map__avail mr10" }),
                _vm._v(" "),
                _c("p", { staticClass: "fz16" }, [
                  _vm._v(_vm._s(_vm.$t("crt_tx18")))
                ])
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "flex1 flex-v-center" }, [
                _c("div", { staticClass: "map__not-avail mr10" }),
                _vm._v(" "),
                _c("p", { staticClass: "fz16" }, [
                  _vm._v(_vm._s(_vm.$t("crt_tx19")))
                ])
              ])
            ])
          ]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "mb20" },
            [
              _c("block-input", {
                attrs: {
                  label: _vm.$t("crt_lb15"),
                  error: _vm.errors["description2"],
                  textarea: true
                },
                model: {
                  value: _vm.travelLocal["description2"],
                  callback: function($$v) {
                    _vm.$set(_vm.travelLocal, "description2", $$v)
                  },
                  expression: "travelLocal['description2']"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "mb20" },
            [
              _c("block-input", {
                attrs: {
                  label: _vm.$t("crt_lb8"),
                  error: _vm.errors["description1"],
                  textarea: true
                },
                model: {
                  value: _vm.travelLocal["description1"],
                  callback: function($$v) {
                    _vm.$set(_vm.travelLocal, "description1", $$v)
                  },
                  expression: "travelLocal['description1']"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            [
              _c("p", { staticClass: "fz17 fw600 mb10" }, [
                _vm._v(_vm._s(_vm.$t("crt_lb10")))
              ]),
              _vm._v(" "),
              _c("block-multi-photo", {
                attrs: { files: _vm.travelLocal["images"], max: 10 },
                model: {
                  value: _vm.travelLocal["images"],
                  callback: function($$v) {
                    _vm.$set(_vm.travelLocal, "images", $$v)
                  },
                  expression: "travelLocal['images']"
                }
              })
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c("div", { staticClass: "col6 col12-768 mb20 trvl1" }, [
        _c(
          "div",
          { staticClass: "mb20 hide-550" },
          [
            _c("block-input", {
              attrs: { label: _vm.$t("crt_tx16"), error: _vm.errors["name"] },
              model: {
                value: _vm.travelLocal["name"],
                callback: function($$v) {
                  _vm.$set(_vm.travelLocal, "name", $$v)
                },
                expression: "travelLocal['name']"
              }
            })
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          { class: [_vm.travelLocal["surface"] ? "mb10" : "mb20"] },
          [
            _c("block-select", {
              attrs: {
                label: _vm.$t("travel.surface_name"),
                values: _vm.$t("travel.surfaces"),
                error: _vm.errors["surface"]
              },
              model: {
                value: _vm.travelLocal["surface"],
                callback: function($$v) {
                  _vm.$set(_vm.travelLocal, "surface", $$v)
                },
                expression: "travelLocal['surface']"
              }
            })
          ],
          1
        ),
        _vm._v(" "),
        _vm.travelLocal["surface"]
          ? _c(
              "div",
              { staticClass: "mb20" },
              [
                _c("block-multi-checkbox", {
                  attrs: {
                    label: _vm.$t("crt_tx15"),
                    error: _vm.errors["transports"],
                    all_transports: _vm.all_transports,
                    recommended: _vm.recommendedTransports
                  },
                  model: {
                    value: _vm.travelLocal["transports"],
                    callback: function($$v) {
                      _vm.$set(_vm.travelLocal, "transports", $$v)
                    },
                    expression: "travelLocal['transports']"
                  }
                })
              ],
              1
            )
          : _vm._e(),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "mb20" },
          [
            _c("block-select", {
              attrs: {
                label: _vm.$t("text.difficult_name"),
                values: _vm.$t("text.difficult"),
                error: _vm.errors["difficult"]
              },
              model: {
                value: _vm.travelLocal["difficult"],
                callback: function($$v) {
                  _vm.$set(_vm.travelLocal, "difficult", $$v)
                },
                expression: "travelLocal['difficult']"
              }
            })
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "mb20" },
          [
            _c("block-select", {
              attrs: {
                label: _vm.$t("text.escort_name"),
                values: _vm.$t("text.escort"),
                error: _vm.errors["escort"]
              },
              model: {
                value: _vm.travelLocal["escort"],
                callback: function($$v) {
                  _vm.$set(_vm.travelLocal, "escort", $$v)
                },
                expression: "travelLocal['escort']"
              }
            })
          ],
          1
        )
      ])
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "form-width mb10" }, [
      _vm.travelLocal["id"]
        ? _c("p", { staticClass: "fz16 lh14 mb10 color-gray" }, [
            _vm._v(_vm._s(_vm.$t("crt_btn1")))
          ])
        : _c("p", { staticClass: "fz16 lh14 mb10 color-gray" }, [
            _vm._v(_vm._s(_vm.$t("crt_btn1_1")))
          ]),
      _vm._v(" "),
      _vm.travelLocal["id"]
        ? _c("p", { staticClass: "fz16 lh14 mb10 color-gray" }, [
            _vm._v(_vm._s(_vm.$t("crt_btn2_1")))
          ])
        : _c("p", { staticClass: "fz16 lh14 mb10 color-gray" }, [
            _vm._v(_vm._s(_vm.$t("crt_btn2")))
          ])
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "mb80" }, [
      _c("div", { staticClass: "flex1 mb20" }, [
        _vm.travelLocal["id"]
          ? _c(
              "button",
              {
                staticClass: "btn mr10",
                on: {
                  click: function($event) {
                    $event.preventDefault()
                    return _vm.prepareToSave.apply(null, arguments)
                  }
                }
              },
              [
                _vm._v(
                  "\n                " +
                    _vm._s(_vm.$t("text.create_edit")) +
                    "\n            "
                )
              ]
            )
          : _c(
              "button",
              {
                staticClass: "btn mr10",
                on: {
                  click: function($event) {
                    $event.preventDefault()
                    return _vm.prepareToSave.apply(null, arguments)
                  }
                }
              },
              [_vm._v(_vm._s(_vm.$t("create")))]
            ),
        _vm._v(" "),
        !_vm.travelLocal["id"]
          ? _c(
              "button",
              {
                staticClass: "btn btn_trs mr10",
                on: {
                  click: function($event) {
                    $event.preventDefault()
                    return _vm.saveDraft.apply(null, arguments)
                  }
                }
              },
              [
                _vm._v(
                  "\n                " +
                    _vm._s(_vm.$t("text.draft")) +
                    "\n            "
                )
              ]
            )
          : _vm._e(),
        _vm._v(" "),
        _vm.travelLocal["id"] && _vm.travelLocal["draft"] == 0
          ? _c(
              "button",
              {
                staticClass: "btn btn_trs mr10",
                on: {
                  click: function($event) {
                    $event.preventDefault()
                    return _vm.saveDraft.apply(null, arguments)
                  }
                }
              },
              [_vm._v(_vm._s(_vm.$t("text.draft_move")) + "\n            ")]
            )
          : _vm._e(),
        _vm._v(" "),
        _vm.travelLocal["id"] && _vm.travelLocal["draft"] == 1
          ? _c(
              "button",
              {
                staticClass: "btn btn_trs mr10",
                on: {
                  click: function($event) {
                    $event.preventDefault()
                    return _vm.saveClean.apply(null, arguments)
                  }
                }
              },
              [_vm._v(_vm._s(_vm.$t("text.draft_publish")) + "\n            ")]
            )
          : _vm._e(),
        _vm._v(" "),
        _vm.travelLocal["id"]
          ? _c(
              "button",
              {
                staticClass: "btn btn_red",
                on: {
                  click: function($event) {
                    $event.preventDefault()
                    return _vm.destroy.apply(null, arguments)
                  }
                }
              },
              [
                _vm._v(
                  "\n                " +
                    _vm._s(_vm.$t("text.delete")) +
                    "\n            "
                )
              ]
            )
          : _vm._e()
      ]),
      _vm._v(" "),
      _c("div", [
        _vm.travelLocal["id"] && _vm.travelLocal["draft"] == 0
          ? _c("a", { staticClass: "btn", attrs: { href: _vm.url_show } }, [
              _vm._v(
                "\n                " +
                  _vm._s(_vm.$t("text.go_to_card")) +
                  "\n            "
              )
            ])
          : _vm._e()
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }