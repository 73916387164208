var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "flex flex-wrap" }, [
    _c(
      "div",
      { staticClass: "col6 mb20 col12-768" },
      [
        _c("p", { staticClass: "fz16 lh14 mb10" }, [
          _c("span", { domProps: { innerHTML: _vm._s(_vm.$t("acn_tx12")) } }),
          _vm._v(" "),
          _c(
            "a",
            { staticClass: "link link-blue", attrs: { href: _vm.data_url } },
            [_vm._v(_vm._s(_vm.$t("acn_tx13")))]
          )
        ]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "mb10" },
          [
            _c("block-checkbox", {
              attrs: { label: _vm.$t("acn_tx1") },
              model: {
                value: _vm.localNotification["telegram_enable"],
                callback: function($$v) {
                  _vm.$set(_vm.localNotification, "telegram_enable", $$v)
                },
                expression: "localNotification['telegram_enable']"
              }
            })
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "vue-slide-up-down",
          {
            attrs: {
              active: !!_vm.localNotification["telegram_enable"],
              name: "slide"
            }
          },
          [
            _c(
              "div",
              { staticClass: "mb10" },
              [
                _c("p", { staticClass: "fz16 lh14 mb10" }, [
                  _vm._v(
                    "\n                    " +
                      _vm._s(_vm.$t("acn_tx8")) +
                      "\n                    "
                  ),
                  _c(
                    "a",
                    {
                      staticClass: "link link-blue",
                      attrs: {
                        target: "_blank",
                        href: "https://t.me/getmyid_bot"
                      }
                    },
                    [_vm._v("@getmyid_bot")]
                  )
                ]),
                _vm._v(" "),
                _c("block-input", {
                  attrs: {
                    label: _vm.$t("acn_tx7"),
                    error: _vm.errors["telegram"]
                  },
                  model: {
                    value: _vm.localNotification["telegram"],
                    callback: function($$v) {
                      _vm.$set(_vm.localNotification, "telegram", $$v)
                    },
                    expression: "localNotification['telegram']"
                  }
                })
              ],
              1
            ),
            _vm._v(" "),
            _c("p", { staticClass: "fz16 lh14" }, [
              _vm._v(
                "\n                " +
                  _vm._s(_vm.$t("acn_tx9")) +
                  "\n                "
              ),
              _c(
                "a",
                {
                  staticClass: "link link-blue",
                  attrs: { target: "_blank", href: "https://t.me/tropkaBot" }
                },
                [_vm._v("@tropkaBot")]
              )
            ])
          ]
        ),
        _vm._v(" "),
        _c(
          "div",
          [
            _c(
              "div",
              { staticClass: "mb10" },
              [
                _c("block-checkbox", {
                  attrs: { label: _vm.$t("acn_tx2") },
                  model: {
                    value: _vm.localNotification["email_enable"],
                    callback: function($$v) {
                      _vm.$set(_vm.localNotification, "email_enable", $$v)
                    },
                    expression: "localNotification['email_enable']"
                  }
                })
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "vue-slide-up-down",
              {
                attrs: {
                  active: !!_vm.localNotification["email_enable"],
                  name: "slide"
                }
              },
              [
                _c("p", { staticClass: "fz16 lh14" }, [
                  _vm._v(_vm._s(_vm.$t("acn_tx10")))
                ])
              ]
            )
          ],
          1
        )
      ],
      1
    ),
    _vm._v(" "),
    _c("div", { staticClass: "col6 mb20 col12-768" }, [
      _c("p", { staticClass: "mb10 fz17 fw600 lh11 mb10" }, [
        _vm._v(_vm._s(_vm.$t("acn_tx11")))
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "mb10" },
        [
          _c("block-checkbox", {
            attrs: { label: _vm.$t("acn_tx4") },
            model: {
              value: _vm.localNotification["travel_enable"],
              callback: function($$v) {
                _vm.$set(_vm.localNotification, "travel_enable", $$v)
              },
              expression: "localNotification['travel_enable']"
            }
          })
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "mb10" },
        [
          _c("block-checkbox", {
            attrs: { label: _vm.$t("acn_tx5") },
            model: {
              value: _vm.localNotification["event_enable"],
              callback: function($$v) {
                _vm.$set(_vm.localNotification, "event_enable", $$v)
              },
              expression: "localNotification['event_enable']"
            }
          })
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "mb10 color-gray" },
        [
          _c("block-checkbox", {
            attrs: { label: _vm.$t("acn_tx6") },
            model: {
              value: _vm.localNotification["help_enable"],
              callback: function($$v) {
                _vm.$set(_vm.localNotification, "help_enable", $$v)
              },
              expression: "localNotification['help_enable']"
            }
          })
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        [
          _c("block-checkbox", {
            attrs: { label: _vm.$t("acn_tx14") },
            model: {
              value: _vm.localNotification["message_enable"],
              callback: function($$v) {
                _vm.$set(_vm.localNotification, "message_enable", $$v)
              },
              expression: "localNotification['message_enable']"
            }
          })
        ],
        1
      )
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "col12" }, [
      _c("button", { staticClass: "btn", on: { click: _vm.save } }, [
        _vm._v(_vm._s(_vm.$t("account.acd_tx3")))
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }