var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      { staticClass: "mb20" },
      [
        _c("block-input", {
          attrs: {
            label: _vm.$t("auth.input_email"),
            error: _vm.errors["email"]
          },
          model: {
            value: _vm.form["email"],
            callback: function($$v) {
              _vm.$set(_vm.form, "email", $$v)
            },
            expression: "form['email']"
          }
        })
      ],
      1
    ),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "mb20" },
      [
        _c("block-input", {
          attrs: {
            label: _vm.$t("auth.input_password"),
            error: _vm.errors["password"],
            type: "password"
          },
          model: {
            value: _vm.form["password"],
            callback: function($$v) {
              _vm.$set(_vm.form, "password", $$v)
            },
            expression: "form['password']"
          }
        })
      ],
      1
    ),
    _vm._v(" "),
    _c(
      "button",
      {
        staticClass: "btn btn_med btn_full",
        attrs: { type: "submit" },
        on: {
          click: function($event) {
            $event.preventDefault()
            return _vm.update.apply(null, arguments)
          }
        }
      },
      [_vm._v("\n        " + _vm._s(_vm.$t("reset")) + "\n    ")]
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }