module.exports = {
    "crt_tx5": "Clear route",
    "crt_tx6": "Full screen map",
    "crt_tx7": "Minimize map",
    "crt_tx8": "Clear route",
    "lst_tx4": "Show/hide start points",
    "lst_tx4_1": "Show start points",
    "lst_tx4_2": "Hide start points",
    "lst_tx5": "Show/hide finish points",
    "lst_tx5_1": "Show finish points",
    "lst_tx5_2": "Hide finish points",
}
