var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "flex1 flex-v-start mb5" }, [
      _vm.event["status"] == 1
        ? _c(
            "a",
            {
              staticClass: "link link-orange mr10 fz16 lh12",
              attrs: { href: _vm.event["href"] }
            },
            [
              _vm._v(
                "\n            " +
                  _vm._s(_vm.event["name"] ? _vm.event["name"] + ", " : "")
              ),
              _vm.event["name"] ? _c("br") : _vm._e(),
              _vm._v(" "),
              _vm.event["start_formatted"]
                ? _c("span", [
                    _vm.event["before_start"]["invert"]
                      ? _c("span", [_vm._v(_vm._s(_vm.$t("event.txt1")) + ":")])
                      : _c("span", [
                          _vm._v(_vm._s(_vm.$t("event.txt1_2")) + ":")
                        ]),
                    _vm._v(
                      "\n                " +
                        _vm._s(_vm.event["start_formatted"]) +
                        "\n            "
                    )
                  ])
                : _c("span", [
                    _vm._v(
                      "\n                " +
                        _vm._s(_vm.$t("event.txt1_1")) +
                        ",\n            "
                    )
                  ]),
              _vm._v(
                "\n            " +
                  _vm._s(_vm.$t("event.txt2")) +
                  ' "' +
                  _vm._s(_vm.event["travel"]["name"]) +
                  '"\n            '
              ),
              _vm.event["start_formatted"]
                ? _c("span", [
                    _vm._v("\n                ("),
                    _vm.event["before_start"]["invert"]
                      ? _c("span", [_vm._v(_vm._s(_vm.$t("event.prt_tx6")))])
                      : _vm._e(),
                    _vm._v(
                      "\n                " +
                        _vm._s(_vm.event["before_start"]["days"]) +
                        "\n                " +
                        _vm._s(_vm.$t("event.prt_tx6_1")) +
                        "\n                " +
                        _vm._s(_vm.event["before_start"]["h"]) +
                        "\n                " +
                        _vm._s(_vm.$t("event.prt_tx6_2")) +
                        "\n            "
                    ),
                    !_vm.event["before_start"]["invert"]
                      ? _c("span", [_vm._v(_vm._s(_vm.$t("event.txt1_3")))])
                      : _vm._e(),
                    _vm._v(")\n            ")
                  ])
                : _vm._e()
            ]
          )
        : _c("span", { staticClass: "mr10 fz16 lh12 color-error" }, [
            _c("span", [
              _vm._v(
                _vm._s(
                  _vm.getNameById(_vm.event["status"], _vm.$t("event.states"))
                )
              )
            ]),
            _c("br"),
            _vm._v(
              "\n            " +
                _vm._s(_vm.event["name"] ? _vm.event["name"] + ", " : "")
            ),
            _vm.event["name"] ? _c("br") : _vm._e(),
            _vm._v(" "),
            _vm.event["start_formatted"]
              ? _c("span", [
                  _vm.event["before_start"]["invert"]
                    ? _c("span", [_vm._v(_vm._s(_vm.$t("event.txt1")) + ":")])
                    : _c("span", [
                        _vm._v(_vm._s(_vm.$t("event.txt1_2")) + ":")
                      ]),
                  _vm._v(
                    "\n                " +
                      _vm._s(_vm.event["start_formatted"]) +
                      "\n            "
                  )
                ])
              : _vm._e()
          ]),
      _vm._v(" "),
      _c("span", { staticClass: "card-bages" }, [
        _vm.event["draft"]
          ? _c(
              "span",
              {
                staticClass:
                  "card-bages__item card-bages__item_draft tooltip-left",
                attrs: { "data-title": _vm.$t("event.txt3") }
              },
              [
                _c("svg", [
                  _c("use", { attrs: { "xlink:href": "#ico-draft" } })
                ])
              ]
            )
          : _vm._e(),
        _vm._v(" "),
        _vm.event["payment"]
          ? _c(
              "span",
              {
                staticClass:
                  "card-bages__item card-bages__item_pay tooltip-left",
                attrs: { "data-title": _vm.$t("event.txt4") }
              },
              [
                _c("svg", [
                  _c("use", { attrs: { "xlink:href": "#ico-payment" } })
                ])
              ]
            )
          : _vm._e(),
        _vm._v(" "),
        _vm.event["type"] == 2
          ? _c(
              "span",
              {
                staticClass:
                  "card-bages__item card-bages__item_type tooltip-left",
                attrs: { "data-title": _vm.$t("event.type")[1]["name"] }
              },
              [
                _c("svg", [
                  _c("use", { attrs: { "xlink:href": "#ico-event-type2" } })
                ])
              ]
            )
          : _vm._e(),
        _vm._v(" "),
        _vm.event["type"] == 3
          ? _c(
              "span",
              {
                staticClass:
                  "card-bages__item card-bages__item_type tooltip-left",
                attrs: { "data-title": _vm.$t("event.type")[2]["name"] }
              },
              [
                _c("svg", [
                  _c("use", { attrs: { "xlink:href": "#ico-event-type3" } })
                ])
              ]
            )
          : _vm._e(),
        _vm._v(" "),
        _vm.event["passenger"]
          ? _c(
              "span",
              {
                staticClass:
                  "card-bages__item card-bages__item_passenger tooltip-left",
                attrs: { "data-title": _vm.$t("event.txt5") }
              },
              [
                _c("svg", [
                  _c("use", { attrs: { "xlink:href": "#ico-passenger" } })
                ])
              ]
            )
          : _vm._e()
      ])
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "flex1 flex-wrap mb10 lh12" }, [
      _c("p", { staticClass: "fz16 mr5" }, [
        _vm._v(_vm._s(_vm.event["country_name"]) + ",")
      ]),
      _vm._v(" "),
      _c("p", { staticClass: "fz16 mr5" }, [
        _vm._v(_vm._s(_vm.event["travel"]["region"]) + ",")
      ]),
      _vm._v(" "),
      _c("p", { staticClass: "fz16 mr5" }, [
        _vm._v(
          _vm._s(_vm.event["travel"]["distance"]) +
            " " +
            _vm._s(_vm.$t("text.km")) +
            ","
        )
      ]),
      _vm._v(" "),
      _c("p", { staticClass: "fz16 mr5" }, [
        _vm._v(
          _vm._s(_vm.event["length_count"]) +
            " " +
            _vm._s(_vm.event["length_text"])
        )
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }