module.exports = {
    "crt_tx5": "Очистить маршрут",
    "crt_tx6": "Развернуть карту",
    "crt_tx7": "Свернуть карту",
    "crt_tx8": "Очистить маршрут",
    "lst_tx4": "Показать/скрыть точки старта",
    "lst_tx4_1": "Показать точки старта",
    "lst_tx4_2": "Скрыть точки старта",
    "lst_tx5": "Показать/скрыть точки финиша",
    "lst_tx5_1": "Показать точки финиша",
    "lst_tx5_2": "Скрыть точки финиша",
}
