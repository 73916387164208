var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "mb60" }, [
    _c("h2", { staticClass: "fz36 fw600 mb20" }, [
      _vm._v("Блок администратора")
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "flex" }, [
      _vm.event["before_start"]["invert"]
        ? _c("div", { staticClass: "col6 col12-768" }, [
            _c("div", { staticClass: "card-border form-width" }, [
              _c("p", { staticClass: "fz20 fw600 color-green mb20" }, [
                _vm._v("Изменение статуса события")
              ]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "mb20" },
                [
                  _c("block-select", {
                    attrs: {
                      label: "Новый статус",
                      values: _vm.values,
                      error: _vm.errors["status"]
                    },
                    model: {
                      value: _vm.form["status"],
                      callback: function($$v) {
                        _vm.$set(_vm.form, "status", $$v)
                      },
                      expression: "form['status']"
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "mb20" },
                [
                  _c(
                    "div",
                    { staticClass: "mb10" },
                    [
                      _c("block-checkbox", {
                        attrs: { label: "Уведомить участников" },
                        model: {
                          value: _vm.form["notify"],
                          callback: function($$v) {
                            _vm.$set(_vm.form, "notify", $$v)
                          },
                          expression: "form['notify']"
                        }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("block-input", {
                    attrs: {
                      label: "Причина (будет отправлена участникам)",
                      error: _vm.errors["message"]
                    },
                    model: {
                      value: _vm.form["message"],
                      callback: function($$v) {
                        _vm.$set(_vm.form, "message", $$v)
                      },
                      expression: "form['message']"
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "button",
                {
                  staticClass: "btn btn_red mb20",
                  on: { click: _vm.saveStatus }
                },
                [_vm._v("Изменить статус")]
              )
            ])
          ])
        : _vm._e()
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }