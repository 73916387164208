var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "st-input1", class: _vm.classObject }, [
      _c("label", { staticClass: "st-input1__label" }, [
        _vm._v(_vm._s(_vm.label))
      ]),
      _vm._v(" "),
      this.textarea
        ? _c("textarea", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.valueLocal,
                expression: "valueLocal"
              }
            ],
            staticClass: "st-input1__input",
            domProps: { value: _vm.valueLocal },
            on: {
              focus: _vm.focusInput,
              blur: _vm.blurInput,
              keyup: function($event) {
                if (
                  !$event.type.indexOf("key") &&
                  _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                ) {
                  return null
                }
                return _vm.pressedEnter.apply(null, arguments)
              },
              input: function($event) {
                if ($event.target.composing) {
                  return
                }
                _vm.valueLocal = $event.target.value
              }
            }
          })
        : _vm.typeLocal === "checkbox"
        ? _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.valueLocal,
                expression: "valueLocal"
              }
            ],
            staticClass: "st-input1__input",
            class: [_vm.input_class ? _vm.input_class : ""],
            attrs: {
              autocomplete: [_vm.autocompleteoff ? "new-password" : ""],
              type: "checkbox"
            },
            domProps: {
              checked: Array.isArray(_vm.valueLocal)
                ? _vm._i(_vm.valueLocal, null) > -1
                : _vm.valueLocal
            },
            on: {
              focus: _vm.focusInput,
              blur: _vm.blurInput,
              change: function($event) {
                var $$a = _vm.valueLocal,
                  $$el = $event.target,
                  $$c = $$el.checked ? true : false
                if (Array.isArray($$a)) {
                  var $$v = null,
                    $$i = _vm._i($$a, $$v)
                  if ($$el.checked) {
                    $$i < 0 && (_vm.valueLocal = $$a.concat([$$v]))
                  } else {
                    $$i > -1 &&
                      (_vm.valueLocal = $$a
                        .slice(0, $$i)
                        .concat($$a.slice($$i + 1)))
                  }
                } else {
                  _vm.valueLocal = $$c
                }
              }
            }
          })
        : _vm.typeLocal === "radio"
        ? _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.valueLocal,
                expression: "valueLocal"
              }
            ],
            staticClass: "st-input1__input",
            class: [_vm.input_class ? _vm.input_class : ""],
            attrs: {
              autocomplete: [_vm.autocompleteoff ? "new-password" : ""],
              type: "radio"
            },
            domProps: { checked: _vm._q(_vm.valueLocal, null) },
            on: {
              focus: _vm.focusInput,
              blur: _vm.blurInput,
              change: function($event) {
                _vm.valueLocal = null
              }
            }
          })
        : _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.valueLocal,
                expression: "valueLocal"
              }
            ],
            staticClass: "st-input1__input",
            class: [_vm.input_class ? _vm.input_class : ""],
            attrs: {
              autocomplete: [_vm.autocompleteoff ? "new-password" : ""],
              type: _vm.typeLocal
            },
            domProps: { value: _vm.valueLocal },
            on: {
              focus: _vm.focusInput,
              blur: _vm.blurInput,
              input: function($event) {
                if ($event.target.composing) {
                  return
                }
                _vm.valueLocal = $event.target.value
              }
            }
          }),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "st-input1__show", on: { click: _vm.togglePassword } },
        [
          _vm.typeLocal == "password"
            ? _c("svg", { staticClass: "st-input1__show-close" }, [
                _c("use", { attrs: { "xlink:href": "#ico-eye-close" } })
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.typeLocal == "text" && _vm.type == "password"
            ? _c("svg", { staticClass: "st-input1__show-open" }, [
                _c("use", { attrs: { "xlink:href": "#ico-eye-open" } })
              ])
            : _vm._e()
        ]
      )
    ]),
    _vm._v(" "),
    this.errorLocal
      ? _c("div", { staticClass: "st-input1__error" }, [
          _vm._v(_vm._s(this.errorLocal[0]))
        ])
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }