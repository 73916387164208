var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "flex flex-wrap-550" }, [
      _c("div", { staticClass: "col9 col12-550 mb10" }, [
        _c("div", { staticClass: "card-border" }, [
          _vm.interlocutor_id
            ? _c("div", { staticClass: "message" }, [
                _c(
                  "p",
                  {
                    staticClass:
                      "fz17 lh15 mb10 fw600 flex1 flex-v-center flex-wrap-550"
                  },
                  [
                    _c("span", { staticClass: "mr5" }, [
                      _vm._v(_vm._s(_vm.$t("ms_tx1")))
                    ]),
                    _vm._v(" "),
                    _c(
                      "a",
                      {
                        staticClass: "link link-blue flex1 flex-v-center mr5",
                        attrs: { href: _vm.interlocutor["href"] }
                      },
                      [
                        _c("span", [
                          _vm._v(_vm._s(_vm.interlocutor["showname"]))
                        ]),
                        _vm._v(" "),
                        _vm.interlocutor["id"] == _vm.admin_chat
                          ? _c(
                              "svg",
                              { attrs: { width: "1rem", height: "1rem" } },
                              [
                                _c("use", {
                                  attrs: { "xlink:href": "#ico-check" }
                                })
                              ]
                            )
                          : _vm._e()
                      ]
                    ),
                    _vm._v(" "),
                    _vm.interlocutor["is_online"]
                      ? _c("span", { staticClass: "color-green fz12" }, [
                          _vm._v("online")
                        ])
                      : _c("span", { staticClass: "color-gray fz12" }, [
                          _vm._v(
                            "online: " + _vm._s(_vm.interlocutor["was_online"])
                          )
                        ])
                  ]
                ),
                _vm._v(" "),
                _c("div", { staticClass: "messages mb20" }, [
                  this.messages.length
                    ? _c(
                        "div",
                        _vm._l(this.messages, function(message) {
                          return _c("div", [
                            _c(
                              "div",
                              {
                                staticClass: "messages__item mb10",
                                class: [
                                  message["is_my"] ? "messages__item_my" : ""
                                ]
                              },
                              [
                                !message["is_my"]
                                  ? _c(
                                      "p",
                                      {
                                        staticClass:
                                          "color-blue fz16 fw600 mb10",
                                        attrs: { target: "_blank" }
                                      },
                                      [
                                        _vm._v(
                                          "\n                                        " +
                                            _vm._s(message["user"]["showname"])
                                        )
                                      ]
                                    )
                                  : _vm._e(),
                                _vm._v(" "),
                                _c("p", { staticClass: "fz16 mb5" }, [
                                  _vm._v(_vm._s(message["text"]))
                                ]),
                                _vm._v(" "),
                                _c("p", { staticClass: "color-gray fz12" }, [
                                  _vm._v(_vm._s(message["created_formatted"]))
                                ])
                              ]
                            )
                          ])
                        }),
                        0
                      )
                    : _c("div", [
                        _c("p", { staticClass: "fz16 color-gray p17" }, [
                          _vm._v(_vm._s(_vm.$t("ms_tx5")))
                        ])
                      ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "messages__form" }, [
                  _c(
                    "div",
                    { staticClass: "mb20" },
                    [
                      _c("block-input", {
                        attrs: { label: _vm.$t("ms_tx2"), textarea: true },
                        on: { pressedEnter: _vm.sendMessage },
                        model: {
                          value: _vm.text,
                          callback: function($$v) {
                            _vm.text = $$v
                          },
                          expression: "text"
                        }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "button",
                    { staticClass: "btn", on: { click: _vm.sendMessage } },
                    [_vm._v(_vm._s(_vm.$t("ms_tx3")))]
                  )
                ])
              ])
            : _c("div", [
                _c("p", { staticClass: "fz20 fw500" }, [
                  _vm._v(_vm._s(_vm.$t("acc_tx11")))
                ])
              ])
        ])
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "col3 col12-550 mb10" }, [
        _c("div", { staticClass: "card-border" }, [
          _c("p", { staticClass: "fz20 fw500 mb30" }, [
            _vm._v(_vm._s(_vm.$t("acc_tx10")))
          ]),
          _vm._v(" "),
          _c(
            "ul",
            { staticClass: "fz17 lh15" },
            _vm._l(_vm.user_list, function(interlocutor) {
              return _c("li", {
                staticClass: "link mb5",
                class: {
                  "color-blue": interlocutor["id"] == _vm.interlocutor_id
                },
                domProps: { innerHTML: _vm._s(interlocutor["name"]) },
                on: {
                  click: function($event) {
                    _vm.interlocutor_id = interlocutor["id"]
                  }
                }
              })
            }),
            0
          )
        ])
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }